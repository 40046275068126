<template>
  <div>
    <h1 class="title m-4">會員管理－編輯會員</h1>
    <div class="form">
      <div class="addformContent border m-4">
        <div class="form-title">
          <h4>編輯會員</h4>
        </div>
        <Form @submit="onSubmit">
          <div class="container mb-5 pb-5">
            <div class="row clearfix">
              <div class="col-12 pb-5 memberContent">
                <div class="row">
                  <div class="col-md-5 mt-3">
                    <h5 class="d-inline-block">姓名</h5>
                    <error-message name="userName" class="error form-error"></error-message>
                    <Field
                      :rules="validate"
                      as="input"
                      type="text"
                      class="form-control"
                      name="userName"
                      v-model.trim="form.userName"
                    />
                  </div>
                  <div class="col-md-7 mt-3">
                    <h5 class="d-inline-block">手機</h5>
                    <input
                      type="text"
                      class="form-control"
                      name="userPhone"
                      v-model.trim="form.userPhone"
                      disabled
                    />
                  </div>
                  <div class="col-md-5 mt-3 calendar">
                    <h5>出生年月日日</h5>
                    <error-message name="userBirth" class="error form-error"></error-message>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="validate"
                        as="datepicker"
                        name="userBirth"
                        v-model="form.userBirth"
                      />
                      <datepicker v-model="form.userBirth" inputFormat="yyyy/MM/dd" />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-7 mt-3 calendar">
                    <h5 class="d-inline-block">Email</h5>
                    <error-message name="Email" class="error form-error"></error-message>
                    <Field
                      rules="email|required"
                      as="input"
                      type="text"
                      class="form-control"
                      name="Email"
                      v-model.trim="form.userMail"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">常用地址1</h5>
                    <input
                      type="text"
                      class="form-control"
                      name="address1"
                      v-model.trim="form.commonAddress1"
                    />
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">常用地址2</h5>
                    <input
                      type="text"
                      class="form-control"
                      name="address2"
                      v-model.trim="form.commonAddress2"
                    />
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">常用地址3</h5>
                    <input
                      type="text"
                      class="form-control"
                      name="address3"
                      v-model.trim="form.commonAddress3"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 mt-3 text-center">
                <button type="submit" class="save btn btn-main px-4">儲存</button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';
// import { ref } from 'vue';
import Swal from 'sweetalert2';

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      userPhone: '',
      form: '',
      staffId: '',
    };
  },
  methods: {
    convert(str) {
      // 日期格式轉換
      const date = new Date(str);
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) {
        return '';
        // eslint-disable-next-line no-else-return
      } else {
        return [date.getFullYear(), mnth, day].join('/');
      }
    },
    getFormData(object) {
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    editForm() {
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/user/userDetailEdit`;
      const formData = new FormData();
      formData.append('userPhone', vm.userPhone);
      vm.$http.post(api, formData).then((res) => {
        if (res.data.status === 200) {
          vm.form = res.data.data;
          vm.form.userBirth = new Date(vm.form.userBirth);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) {
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/user/userDetailProcess`;
      vm.form.userBirth = vm.convert(vm.form.userBirth);
      vm.$http
        .post(api, vm.getFormData(vm.form))
        .then((res) => {
          if (res.data.status === 200) {
            vm.$router.push(`/admin/Member/${vm.staffId}`);
          } else {
            Swal.fire({
              icon: 'error',
              title: '會員更新失敗',
              text: '請再次確認表單是否正確填寫',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          // eslint-disable-next-line no-unused-vars
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    validate(value) {
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.userPhone = atob(this.$route.params.userphone);
    this.editForm();
  },
};
</script>
