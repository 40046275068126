/* eslint-disable no-extend-native */
<template>
  <div>
    <h1 class="title m-4">新增角色</h1>
    <div class="form">
      <div class="addformContent border m-4">
        <div class="form-title">
          <h4>新增角色</h4>
        </div>
        <Form @submit="onSubmit">
          <div class="container mb-5 pb-5">
            <div class="row clearfix">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-4 m-3">
                    <h5 class="d-inline-block">角色名稱</h5>
                    <error-message name="roleName" class="error form-error"></error-message>
                    <Field
                      :rules="validate"
                      as="input"
                      type="text"
                      class="form-control"
                      name="roleName"
                      v-model.trim="form.roleName"
                    />
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5>角色狀態</h5>
                    <error-message name="roleStatus" class="error form-error"></error-message>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="roleStatus"
                        id="role_open"
                        value="T"
                        v-model="form.status"
                        checked
                      />
                      <label class="form-check-label" for="role_open">開啟</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="roleStatus"
                        id="role_close"
                        value="F"
                        v-model="form.status"
                      />
                      <label class="form-check-label" for="role_close">關閉</label>
                    </div>
                  </div>
                  <div class="col-md-8 m-3">
                    <h5 class="mt-4 d-inline-block">角色權限項目</h5>
                    <error-message name="roleAuth" class="error form-error"></error-message>
                    <div class="role_item">
                      <div class="form-check">
                        <Field
                          :rules="schema"
                          type="checkbox"
                          class="form-check-input"
                          id="auth_1"
                          name="roleAuth"
                          value="數據儀表板"
                          v-model="form.roleAuth"
                        />
                        <label class="form-check-label" for="auth_1">
                          數據儀表板
                        </label>
                      </div>
                    </div>
                    <div class="role_item">
                      <div class="form-check">
                        <Field
                          :rules="schema"
                          type="checkbox"
                          class="form-check-input"
                          id="auth_2"
                          name="roleAuth"
                          value="活動管理"
                          v-model="form.roleAuth"
                        />
                        <label class="form-check-label" for="auth_2">
                          活動管理
                        </label>
                      </div>
                    </div>
                    <div class="role_item">
                      <div class="d-flex">
                        <div
                          class="collapsednav-link d-flex align-items-center"
                          data-bs-toggle="collapse"
                          data-bs-target="#sidebar_verify"
                          aria-expanded="false"
                        >
                          <i class="far fa-minus-square"></i>
                        </div>
                        <div class="form-check m-1">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="auth_3"
                            name="roleAuth"
                            value="審核管理"
                            v-model="checkGroup.verify"
                            @click="selectAll('審核管理')"
                          />
                          <label class="form-check-label" for="auth_3">
                            審核管理
                          </label>
                        </div>
                      </div>
                      <ul class="collapse" id="sidebar_verify">
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_4"
                              name="roleAuth"
                              value="發票審核"
                              v-model="form.roleAuth"
                              @click="select('審核管理', '發票審核')"
                              data-vv-validate-on="change|click"
                            />
                            <label class="form-check-label" for="auth_4">
                              發票審核
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_5"
                              name="roleAuth"
                              value="身份審核"
                              v-model="form.roleAuth"
                              @click="select('審核管理', '身份審核')"
                            />
                            <label class="form-check-label" id="auth_5">
                              身份審核
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="role_item">
                      <div class="d-flex">
                        <div
                          class="collapsednav-link d-flex align-items-center"
                          data-bs-toggle="collapse"
                          data-bs-target="#sidebar_archive"
                          aria-expanded="false"
                        >
                          <i class="far fa-minus-square"></i>
                        </div>
                        <div class="form-check m-1">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="auth_6"
                            name="roleAuth"
                            value="資料管理"
                            v-model="checkGroup.archive"
                            @click="selectAll('資料管理')"
                          />
                          <label class="form-check-label" for="auth_6">
                            資料管理
                          </label>
                        </div>
                      </div>
                      <ul class="collapse" id="sidebar_archive">
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_7"
                              name="roleAuth"
                              value="通路管理"
                              v-model="form.roleAuth"
                              @click="select('資料管理', '通路管理')"
                            />
                            <label class="form-check-label" for="auth_7">
                              通路管理
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_8"
                              name="roleAuth"
                              value="品項管理"
                              v-model="form.roleAuth"
                              @click="select('資料管理', '品項管理')"
                            />
                            <label class="form-check-label" for="auth_8">
                              品項管理
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_9"
                              name="roleAuth"
                              value="通路商品管理"
                              v-model="form.roleAuth"
                              @click="select('資料管理', '通路商品管理')"
                            />
                            <label class="form-check-label" for="auth_9">
                              通路商品管理
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="role_item">
                      <div class="d-flex">
                        <div
                          class="collapsednav-link d-flex align-items-center"
                          data-bs-toggle="collapse"
                          data-bs-target="#sidebar_gifts"
                          aria-expanded="false"
                        >
                          <i class="far fa-minus-square"></i>
                        </div>
                        <div class="form-check m-1">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="auth_10"
                            name="roleAuth"
                            value="贈獎管理"
                            v-model="checkGroup.gift"
                            @click="selectAll('贈獎管理')"
                          />
                          <label class="form-check-label" for="auth_10">
                            贈獎管理
                          </label>
                        </div>
                      </div>
                      <ul class="collapse" id="sidebar_gifts">
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_11"
                              name="roleAuth"
                              value="抽獎設定"
                              v-model="form.roleAuth"
                              @click="select('贈獎管理', '抽獎設定')"
                            />
                            <label class="form-check-label" for="auth_11">
                              抽獎設定
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_12"
                              name="roleAuth"
                              value="滿額獎設定"
                              v-model="form.roleAuth"
                              @click="select('贈獎管理', '滿額獎設定')"
                            />
                            <label class="form-check-label" for="auth_12">
                              滿額獎設定
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_14"
                              name="roleAuth"
                              value="紅利獎設定"
                              v-model="form.roleAuth"
                              @click="select('贈獎管理', '紅利獎設定')"
                            />
                            <label class="form-check-label" for="auth_14">
                              紅利獎設定
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_13"
                              name="roleAuth"
                              value="獎品管理"
                              v-model="form.roleAuth"
                              @click="select('贈獎管理', '獎品管理')"
                            />
                            <label class="form-check-label" for="auth_13">
                              獎品管理
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="role_item">
                      <div class="form-check">
                        <Field
                          :rules="schema"
                          type="checkbox"
                          class="form-check-input"
                          id="auth_14"
                          name="roleAuth"
                          value="會員管理"
                          v-model="form.roleAuth"
                        />
                        <label class="form-check-label" for="auth_14">
                          會員管理
                        </label>
                      </div>
                    </div>
                    <div class="role_item">
                      <div class="d-flex">
                        <div
                          class="collapsednav-link d-flex align-items-center"
                          data-bs-toggle="collapse"
                          data-bs-target="#sidebar_authority"
                          aria-expanded="false"
                        >
                          <i class="far fa-minus-square"></i>
                        </div>
                        <div class="form-check m-1">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="auth_15"
                            name="roleAuth"
                            value="權限管理"
                            v-model="checkGroup.auth"
                            @click="selectAll('權限管理')"
                          />
                          <label class="form-check-label" for="auth_15">
                            權限管理
                          </label>
                        </div>
                      </div>
                      <ul class="collapse" id="sidebar_authority">
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_16"
                              name="roleAuth"
                              value="角色管理"
                              @click="select('權限管理', '角色管理')"
                            />
                            <label class="form-check-label" for="auth_16">
                              角色管理
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="form-check">
                            <Field
                              :rules="schema"
                              type="checkbox"
                              class="form-check-input"
                              id="auth_17"
                              name="roleAuth"
                              value="用戶管理"
                              v-model="form.roleAuth"
                              @click="select('權限管理', '用戶管理')"
                            />
                            <label class="form-check-label" for="auth_17">
                              用戶管理
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 mt-3">
                <button type="submit" class="save btn btn-main px-4">儲存</button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      staffId: '',
      roleId: '',
      checkGroup: {
        verify: '',
        verifyMem: ['發票審核', '身份審核'],
        archive: '',
        archiveMem: ['通路管理', '品項管理', '通路商品管理'],
        gift: '',
        giftMem: ['抽獎設定', '滿額獎設定', '獎品管理'],
        auth: '',
        authMem: ['角色管理', '用戶管理'],
      },
      form: {
        roleName: '',
        roleAuth: [],
        status: 'T',
      },
    };
  },
  methods: {
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    editForm() {
      // 編輯角色 -> 載入先前已填寫過資料
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/auth/authRoleDetailEdit`;
      const formData = new FormData();
      formData.append('roleId', vm.roleId);
      if (vm.roleId !== 'new') {
        vm.$http.post(api, formData).then((res) => {
          if (res.data.status === 200) {
            vm.form = res.data.data;
            if (vm.form.roleAuth.includes('發票審核') && vm.form.roleAuth.includes('身份審核')) {
              vm.checkGroup.verify = true;
            }
            if (
              vm.form.roleAuth.includes('通路管理') &&
              vm.form.roleAuth.includes('品項管理') &&
              vm.form.roleAuth.includes('通路商品管理')
            ) {
              vm.checkGroup.archive = true;
            }
            if (
              vm.form.roleAuth.includes('抽獎設定') &&
              vm.form.roleAuth.includes('滿額獎設定') &&
              vm.form.roleAuth.includes('獎品管理')
            ) {
              vm.checkGroup.gift = true;
            }
            if (vm.form.roleAuth.includes('角色管理') && vm.form.roleAuth.includes('用戶管理')) {
              vm.checkGroup.auth = true;
            }
          }
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) {
      // 送出表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/auth/authRoleDetailProcess`;
      if (vm.roleId !== 'new') {
        vm.form.roleId = vm.roleId;
      }
      vm.form.roleAuth = JSON.stringify(vm.form.roleAuth);
      vm.$http
        .post(api, vm.getFormData(vm.form))
        .then((res) => {
          if (res.data.status === 200) {
            vm.$router.push(`/admin/role/${vm.staffId}`);
          } else {
            Swal.fire({
              icon: 'error',
              title: '角色更新失敗',
              html: '請再次確認表單是否正確填寫',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    validate(value) {
      // 表單驗證
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
    // eslint-disable-next-line vue/no-dupe-keys
    schema(value) {
      // 表單驗證 -> 角色權限項目至少勾選一個
      // make sure array not empty
      if (value && value.length) {
        return true;
      }
      return '請至少勾選一個';
    },
    selectAll(input) {
      // 全選角色權限項目 -> 全部勾選則子項目同步勾選
      const vm = this;
      const select = Array.from(vm.form.roleAuth);
      if (input === '審核管理') {
        vm.checkGroup.verify = !vm.checkGroup.verify;
        if (vm.checkGroup.verify) {
          for (let i = 0; i < vm.checkGroup.verifyMem.length; i += 1) {
            if (!select.includes(vm.checkGroup.verifyMem[i])) {
              select.push(vm.checkGroup.verifyMem[i]);
            }
          }
        } else {
          for (let i = 0; i < vm.checkGroup.verifyMem.length; i += 1) {
            if (select.includes(vm.checkGroup.verifyMem[i])) {
              select.splice(select.indexOf(vm.checkGroup.verifyMem[i]), 1);
            }
          }
        }
      } else if (input === '資料管理') {
        vm.checkGroup.archive = !vm.checkGroup.archive;
        if (vm.checkGroup.archive) {
          for (let i = 0; i < vm.checkGroup.archiveMem.length; i += 1) {
            if (!select.includes(vm.checkGroup.archiveMem[i])) {
              select.push(vm.checkGroup.archiveMem[i]);
            }
          }
        } else {
          for (let i = 0; i < vm.checkGroup.archiveMem.length; i += 1) {
            if (select.includes(vm.checkGroup.archiveMem[i])) {
              select.splice(select.indexOf(vm.checkGroup.archiveMem[i]), 1);
            }
          }
        }
      } else if (input === '贈獎管理') {
        vm.checkGroup.gift = !vm.checkGroup.gift;
        if (vm.checkGroup.gift) {
          for (let i = 0; i < vm.checkGroup.giftMem.length; i += 1) {
            if (!select.includes(vm.checkGroup.giftMem[i])) {
              select.push(vm.checkGroup.giftMem[i]);
            }
          }
        } else {
          for (let i = 0; i < vm.checkGroup.giftMem.length; i += 1) {
            if (select.includes(vm.checkGroup.giftMem[i])) {
              select.splice(select.indexOf(vm.checkGroup.giftMem[i]), 1);
            }
          }
        }
      } else if (input === '權限管理') {
        vm.checkGroup.auth = !vm.checkGroup.auth;
        if (vm.checkGroup.auth) {
          for (let i = 0; i < vm.checkGroup.authMem.length; i += 1) {
            if (!select.includes(vm.checkGroup.authMem[i])) {
              select.push(vm.checkGroup.authMem[i]);
            }
          }
        } else {
          for (let i = 0; i < vm.checkGroup.authMem.length; i += 1) {
            if (select.includes(vm.checkGroup.authMem[i])) {
              select.splice(select.indexOf(vm.checkGroup.authMem[i]), 1);
            }
          }
        }
      }
      vm.form.roleAuth = select;
    },
    select(input, value) {
      // 選項角色權限項目 -> 勾選子項目
      const vm = this;
      if (vm.form.roleAuth.includes(value)) {
        vm.form.roleAuth.splice(vm.form.roleAuth.indexOf(value), 1);
      } else {
        vm.form.roleAuth.push(value);
      }
      if (input === '審核管理') {
        vm.checkGroup.verify = false;
        // eslint-disable-next-line no-unused-vars
        let flag = true;
        for (let i = 0; i < vm.checkGroup.verifyMem.length; i += 1) {
          if (vm.form.roleAuth.includes(vm.checkGroup.verifyMem[i]) === false) {
            flag = false;
          }
        }
        if (flag) {
          vm.checkGroup.verify = true;
        }
      } else if (input === '資料管理') {
        vm.checkGroup.archive = false;
        let flag = true;
        for (let i = 0; i < vm.checkGroup.archiveMem.length; i += 1) {
          if (vm.form.roleAuth.includes(vm.checkGroup.archiveMem[i]) === false) {
            flag = false;
          }
        }
        if (flag) {
          vm.checkGroup.archive = true;
        }
      } else if (input === '贈獎管理') {
        vm.checkGroup.gift = false;
        let flag = true;
        for (let i = 0; i < vm.checkGroup.giftMem.length; i += 1) {
          if (vm.form.roleAuth.includes(vm.checkGroup.giftMem[i]) === false) {
            flag = false;
          }
        }
        if (flag) {
          vm.checkGroup.gift = true;
        }
      } else if (input === '權限管理') {
        vm.checkGroup.auth = false;
        let flag = true;
        for (let i = 0; i < vm.checkGroup.authMem.length; i += 1) {
          if (vm.form.roleAuth.includes(vm.checkGroup.authMem[i]) === false) {
            flag = false;
          }
        }
        if (flag) {
          vm.checkGroup.auth = true;
        }
      }
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.roleId = this.$route.params.roleId;
    this.editForm();
  },
};
</script>
