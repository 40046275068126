<template>
  <div>
    <h1 class="title m-4">發票審核－審核發票</h1>
    <div class="container">
      <div class="row">
        <div class="invoice-img col-12 col-md-5">
          <img :src="imgUrl"  class="img-fluid" alt="invoice">
        </div>
        <div class="col-12 col-md-7 mb-3">
          <Form @submit="onSubmit">
          <div class="check-item d-flex align-items-center">
            <p class="checkTitle">發票類型</p>
            <p>{{ invoiceType }}</p>
          </div>
          <div class="check-item d-flex align-items-center">
            <p class="checkTitle">發票登錄日期</p>
            <p>{{ entryDate }}</p>
          </div>
          <div class="check-item d-flex align-items-center">
            <p class="checkTitle">發票開立日期</p>
            <p>{{ invoiceDate }}</p>
          </div>
          <div class="check-item d-flex align-items-center">
            <p class="checkTitle">發票號碼</p>
            <p>{{ checkForm.invoiceCode }}</p>
          </div>
          <div class="check-item">
            <div class="form-group d-flex align-items-center mb-3">
              <label for="store" class="checkTitle">購買通路</label>
              <Field :rules="validate" as="select" class="form-select"
                name="store" id="store"
                aria-label="Default select example"
                v-model="checkForm.store">
                  <option disabled value="">請選擇購買通路</option>
                  <option v-for="(item,index) in shoplist"
                  :key="index">{{ item }}</option>
                </Field>
                <error-message name="store" class="error form-error"></error-message>
            </div>
          </div>
          <div class="check-item">
            <div class="form-group d-flex align-items-center mb-3">
              <label for="storeAddress" class="checkTitle">賣方地址</label>
              <Field :rules="validate" as="input" type="text" class="form-control"
              name="storeAddress" v-model.trim="checkForm.storeAddress" />
              <error-message name="storeAddress" class="error form-error"></error-message>
            </div>
          </div>
          <div class="check-item buy_items" v-for ="(item , index) in checkForm.product"
            :key="index">
            <div class="form-group d-flex align-items-center mb-3">
              <label for="productName" class="checkTitle">購買商品</label>
              <Field :rules="validate" as="input" type="text" class="form-control"
              :name="`productName_${index}`" v-model.trim="item.productName"/>
              <error-message :name="`productName_${index}`"
              class="error form-error"></error-message>
              <button class="btn-plus"
              v-if="index == checkForm.product.length-1" @click="addProduct()">
                <i class="fas fa-plus-circle"></i></button>
              <button class="btn-delete"
              v-if="index != checkForm.product.length-1" @click="delProduct(item)">
                <i class="fas fa-times-circle"></i></button>
            </div>
            <div class="form-group d-flex align-items-center mb-3">
              <label for="price" class="checkTitle">購買金額</label>
              <Field :rules="validate" as="input" type="text"
              class="form-control" v-model.trim="item.price"
              :name="`price_${index}`" inputmode="numeric"/>
              <error-message :name="`price_${index}`"
              class="error form-error"></error-message>
            </div>
            <div class="form-group d-flex align-items-center mb-3">
              <label for="productNum" class="checkTitle">購買數量</label>
              <Field :rules="validate" as="input" type="number" class="form-control"
              v-model="item.productNum"
              :name="`productNum_${index}`" inputmode="numeric" min = 1 />
              <error-message :name="`productNum_${index}`"
              class="error form-error"></error-message>
            </div>
          </div>
          <div class="check-item">
            <div class="form-group d-flex align-items-center mb-3">
                <label for="total" class="checkTitle">全品項金額</label>
                <input type="text" class="form-control"
                name="total" :value="calcTotal()" disabled>
            </div>
          </div>
          <div class="check-item status d-flex align-items-center ">
            <p class="checkTitle my-auto">審核狀態</p>
                <Field :rules="validate" as="select" class="form-select"
                name="certification_status" id="certification_status"
                aria-label="Default select example" v-model="checkForm.status">
                <option value="" disabled>審核狀態</option>
                  <option value="T">登錄成功</option>
                  <option value="F">登錄失敗</option>
                </Field>
                <error-message name="certification_status" class="error form-error"></error-message>
                <Field :rules="validate" as="select" class="form-select"
                name="status_info" id="status_info"
                aria-label="Default select example" v-model="checkForm.statusMsg"
                v-if="checkForm.status == 'F'">
                  <option value="" disabled>請選擇審核失敗原因</option>
                  <option value="D">此發票並非在活動期間購買</option>
                  <option value="S">此發票並非在本活動指定通路購買</option>
                  <option value="I">此發票並無購買仕高利達</option>
                  <option value="M">此發票累計的仕高利達金額未達門檻</option>
                  <option value="U">此發票資訊輸入錯誤</option>
                </Field>
                <error-message name="status_info" class="error form-error"></error-message>
          </div>
          <button type="submit" class="btn btn-main btn-renew">更新</button>
          </Form>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      staffId: '',
      entryDate: '',
      invoiceDate: '',
      invoiceType: '',
      imgUrl: '',
      shoplist: '',
      checkForm: {
        invoiceCode: '',
        store: '',
        total: '',
        status: '',
        statusMsg: '',
        product: [
          {
            productName: '',
            price: '',
            productNum: '',
          },
        ],
      },
    };
  },
  methods: {
    getFormData(object) { // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    addProduct() { // （＋）新增商品群組表單
      this.checkForm.product.push({
        productName: '',
        price: '',
        productNum: '',
      });
    },
    delProduct(item) { // （-）刪除商品群組表單
      const index = this.checkForm.product.indexOf(item);
      if (index !== -1) {
        this.checkForm.product.splice(index, 1);
      }
    },
    calcTotal() { // 計算全品項金額
      let result = 0;
      for (let i = 0; i < this.checkForm.product.length; i += 1) {
        result += Number(this.checkForm.product[i].price)
        * Number(this.checkForm.product[i].productNum);
      }
      this.checkForm.total = String(result);
      return this.checkForm.total;
    },
    customCheck() { // 載入已知資料
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/inv/invCheck`;
      const formData = new FormData();
      formData.append('invoiceCode', vm.checkForm.invoiceCode);
      vm.$http.post(api,
        formData).then((res) => {
        vm.entryDate = res.data.data[0].entryDate;
        vm.invoiceDate = res.data.data[0].invoiceDate;
        vm.invoiceType = res.data.data[0].invoiceType;
        vm.imgUrl = res.data.data[0].imgUrl;
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    shop() { // 導入通路選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actShop`;
      const formData = new FormData();
      formData.append('invoiceCode', vm.checkForm.invoiceCode);
      vm.$http.post(api, formData).then((res) => {
        vm.shoplist = res.data.data;
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) { // 送出表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/inv/invCheckSave`;
      if (vm.checkForm.status === 'T') {
        vm.checkForm.statusMsg = '';
      }
      vm.checkForm.product = JSON.stringify(vm.checkForm.product);
      vm.$http.post(api, vm.getFormData(vm.checkForm)).then((res) => {
        if (res.data.status === 200) {
          window.location.replace(`/admin/invoice/${vm.staffId}`);
        } else {
          Swal.fire({
            icon: 'error',
            html: '請再次確認表單內容是否有誤<br>謝謝',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
    validate(value) { // 表單驗證
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.checkForm.invoiceCode = this.$route.params.invoiceCode;
    this.shop();
    this.customCheck();
  },
};
</script>
