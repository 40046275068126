/* eslint-disable no-const-assign */
<template>
  <div>
    <h1 class="title m-4">新增紅利獎</h1>
    <div class="form">
      <div class="addformContent border m-4">
        <div class="form-title">
          <h4>新增紅利獎</h4>
        </div>
        <div class="container mb-5 pb-5">
          <Form @submit="onSubmit">
            <div class="row clearfix">
              <div class="col-12">
                <div class="row">
                  <div class="mt-3 col-md-3">
                    <h5 class="d-inline-block">選擇活動</h5>
                    <error-message name="actName" class="error form-error"></error-message>
                    <Field
                      :rules="validate"
                      as="select"
                      class="form-select"
                      name="actName"
                      id="actName"
                      aria-label="Default select example"
                      :disabled="bonusGroupID !== 'new'"
                      v-model="form.activityID"
                      @change="actDate(form.activityID)"
                    >
                      <option value="" disabled selected>請選擇活動名稱</option>
                      <option
                        v-for="(item, index) in actlist"
                        :key="index"
                        :value="item.activityID"
                        >{{ item.activityName }}</option
                      >
                    </Field>
                  </div>
                  <div
                    class="mt-3"
                    :class="bonusGroupID !== 'new' ? 'col-md-3' : 'col-md-4'"
                    v-if="bonusGroupID !== 'new'"
                  >
                    <h5 class="d-inline-block">建立時間</h5>
                    <input type="text" class="form-control" v-model="actInfo.createdAt" disabled />
                  </div>
                  <div class="mt-3 col-md-3">
                    <h5>可兌換日</h5>
                    <div class="input-g d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="actInfo.startDate"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="mt-3 col-md-3">
                    <h5>兑換終止日</h5>
                    <div class="input-g d-flex align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="actInfo.exchangeEndDate"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="mt-3 col-md-3">
                    <h5>領獎期限</h5>
                    <div class="input-g d-flex align-items-center">
                      <input type="text" class="form-control" v-model="actInfo.endDate" disabled />
                    </div>
                  </div>
                </div>
              </div>
              <div class="realgift mt-4">
                <div class="d-flex">
                  <h5>實體獎品</h5>
                  <div class="form-check form-check-inline mx-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="realIsable"
                      id="realIsable_T"
                      value="T"
                      v-model="form.realIsable"
                      :disabled="
                        new Date(actInfo.endDate) > new Date() || bonusGroupID === 'new'
                          ? false
                          : true
                      "
                    />
                    <label class="form-check-label" for="realIsable_T"> 開啟</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="realIsable"
                      id="realIsable_F"
                      value="F"
                      v-model="form.realIsable"
                      :disabled="
                        new Date(actInfo.endDate) > new Date() || bonusGroupID === 'new'
                          ? false
                          : true
                      "
                    />
                    <label class="form-check-label" for="realIsable_F"> 關閉</label>
                  </div>
                </div>
                <template v-if="form.realIsable === 'T'">
                  <div
                    class="col-12 col-md-12"
                    v-for="(item, index) in form.realGifts"
                    :key="index"
                  >
                    <div class="row">
                      <div class="col-md-3 mt-3">
                        <div class="gift_items">
                          <div class="giftName">
                            <h5 v-if="index == 0">獎品名稱</h5>
                            <Field
                              :rules="form.realIsable === 'T' ? validate : ''"
                              as="select"
                              class="form-select"
                              :name="`giftName_${index}`"
                              id="giftName"
                              aria-label="Default select example"
                              v-model="item.giftId"
                              :disabled="
                                new Date(actInfo.endDate) > new Date() || bonusGroupID === 'new'
                                  ? false
                                  : true
                              "
                            >
                              <option value="" disabled selected>請選擇獎品名稱</option>
                              <option
                                v-for="(item, index) in giftlist.real"
                                :key="index"
                                :value="item.giftId"
                                >{{ item.giftName }}</option
                              >
                            </Field>
                            <error-message
                              :name="`giftName_${index}`"
                              class="error form-error"
                            ></error-message>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 mt-3">
                        <h5 v-if="index == 0">實體獎品數量</h5>
                        <div class="d-flex">
                          <Field
                            :rules="form.realIsable === 'T' ? validate : ''"
                            as="input"
                            type="number"
                            class="form-control"
                            v-model="item.giftNum"
                            :name="`giftNum_${index}`"
                            inputmode="numeric"
                            min="1"
                            :disabled="
                              new Date(actInfo.endDate) > new Date() || bonusGroupID === 'new'
                                ? false
                                : true
                            "
                          />
                        </div>
                        <error-message
                          :name="`giftNum_${index}`"
                          class="error form-error"
                        ></error-message>
                      </div>
                      <div class="col-md-3 mt-3">
                        <h5 v-if="index == 0">獎項點數</h5>
                        <div class="d-flex">
                          <Field
                            :rules="form.realIsable === 'T' ? validate : ''"
                            as="input"
                            type="number"
                            class="form-control"
                            v-model="item.bonusPoint"
                            :name="`bonusPoint_${index}`"
                            inputmode="numeric"
                            min="1"
                            :disabled="
                              new Date(actInfo.endDate) > new Date() || bonusGroupID === 'new'
                                ? false
                                : true
                            "
                          />
                        </div>
                        <error-message
                          :name="`giftNum_${index}`"
                          class="error form-error"
                        ></error-message>
                      </div>
                      <div class="col-md-3 mt-3">
                        <h5 v-if="index == 0">獎品領取次數</h5>
                        <div class="d-flex">
                          <Field
                            :rules="form.realIsable === 'T' ? validate : ''"
                            as="input"
                            type="number"
                            class="form-control"
                            v-model="item.bonusTimes"
                            :name="`bonusTimes_${index}`"
                            inputmode="numeric"
                            min="1"
                            :disabled="
                              new Date(actInfo.endDate) > new Date() || bonusGroupID === 'new'
                                ? false
                                : true
                            "
                          />

                          <button
                            class="btn-delete"
                            @click="delGift(item, true)"
                            :disabled="
                              form.realGifts.length <= 1
                                ? true
                                : bonusGroupID === 'new'
                                ? false
                                : new Date(actInfo.endDate) > new Date()
                                ? false
                                : true
                            "
                          >
                            <i class="fas fa-times-circle"></i>
                          </button>
                        </div>
                        <error-message
                          :name="`giftNum_${index}`"
                          class="error form-error"
                        ></error-message>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="d-flex justify-content-end" v-if="form.realIsable === 'T'">
                  <button
                    class="btn-plus mt-3"
                    @click="addGift(true)"
                    :disabled="
                      bonusGroupID === 'new'
                        ? false
                        : new Date(actInfo.endDate) > new Date()
                        ? false
                        : true
                    "
                  >
                    <i class="fas fa-plus-circle"></i>
                  </button>
                </div>
              </div>
              <div class="virtualgift mt-4">
                <div class="d-flex">
                  <h5>虛擬獎品</h5>
                  <div class="form-check form-check-inline mx-2">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="virtualIsable"
                      id="virtualIsable_T"
                      value="T"
                      v-model="form.virtualIsable"
                      :disabled="bonusGroupID === 'new' ? false : true"
                    />
                    <label class="form-check-label" for="virtualIsable_T"> 開啟</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="virtualIsable"
                      id="virtualIsable_F"
                      value="F"
                      v-model="form.virtualIsable"
                    />
                    <label class="form-check-label" for="virtualIsable_F"> 關閉</label>
                  </div>
                </div>
                <template v-if="form.virtualIsable === 'T'">
                  <div class="col-12" v-for="(item, index) in form.virtualGifts" :key="index">
                    <div class="row">
                      <div class="col-md-3 mt-3">
                        <div class="gift_items">
                          <div class="giftName">
                            <h5 v-if="index == 0">獎品名稱</h5>
                            <Field
                              :rules="form.virtualIsable === 'T' ? validate : ''"
                              as="select"
                              class="form-select"
                              :name="`virtualgiftName_${index}`"
                              id="virtualgiftName"
                              aria-label="Default select example"
                              v-model="item.giftId"
                              :disabled="item.giftUrl === '-' || bonusGroupID !== 'new'"
                            >
                              <option value="" disabled selected>請選擇獎品名稱</option>
                              <option
                                v-for="(item, index) in giftlist.virtual"
                                :key="index"
                                :value="item.giftId"
                                >{{ item.giftName }}</option
                              >
                            </Field>
                            <error-message
                              :name="`virtualgiftName_${index}`"
                              class="error form-error"
                            ></error-message>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2 mt-3">
                        <h5 v-if="index == 0">獎品數量</h5>
                        <div class="d-flex">
                          <Field
                            :rules="form.virtualIsable === 'T' ? validate : ''"
                            as="input"
                            type="number"
                            class="form-control"
                            v-model="item.giftNum"
                            :name="`virtualgiftNum_${index}`"
                            inputmode="numeric"
                            min="1"
                            :disabled="item.giftUrl === '-' || bonusGroupID !== 'new'"
                          />
                        </div>
                        <error-message
                          :name="`virtualgiftNum_${index}`"
                          class="error form-error"
                        ></error-message>
                      </div>
                      <div class="col-md-3 mt-3">
                        <h5 v-if="index == 0">獎品URL</h5>
                        <div class="d-flex flex-wrap">
                          <div class="input-group">
                            <Field
                              :rules="
                                item.giftUrl !== '-' && form.virtualIsable !== 'F' ? validate : ''
                              "
                              as="input"
                              type="file"
                              class="form-control"
                              placeholder="上傳URL"
                              @change="upload(index, $event)"
                              :name="`giftUrl_${index}`"
                              :disabled="item.giftUrl === '-' || bonusGroupID !== 'new'"
                            />
                          </div>
                          <error-message
                            :name="`giftUrl_${index}`"
                            class="error form-error"
                          ></error-message>
                        </div>
                      </div>
                      <div class="col-md-2 mt-3">
                        <h5 v-if="index == 0">獎項點數</h5>
                        <div class="d-flex">
                          <Field
                            :rules="form.virtualIsable === 'T' ? validate : ''"
                            as="input"
                            type="number"
                            class="form-control"
                            v-model="item.bonusPoint"
                            :name="`virtualbonusPoint_${index}`"
                            inputmode="numeric"
                            min="1"
                            :disabled="item.giftUrl === '-' || bonusGroupID !== 'new'"
                          />
                        </div>
                        <error-message
                          :name="`virtualgiftNum_${index}`"
                          class="error form-error"
                        ></error-message>
                      </div>
                      <div class="col-md-2 mt-3">
                        <h5 v-if="index == 0">領取次數</h5>
                        <div class="d-flex">
                          <Field
                            :rules="form.virtualIsable === 'T' ? validate : ''"
                            as="input"
                            type="number"
                            class="form-control"
                            v-model="item.bonusTimes"
                            :name="`virtualbonusTimes_${index}`"
                            inputmode="numeric"
                            min="1"
                            :disabled="item.giftUrl === '-' || bonusGroupID !== 'new'"
                          />

                          <button
                            class="btn-delete"
                            @click="delGift(item, false)"
                            :disabled="
                              bonusGroupID === 'new'
                                ? form.virtualGifts.length === 1
                                  ? true
                                  : false
                                : true
                            "
                          >
                            <i class="fas fa-times-circle"></i>
                          </button>
                        </div>
                        <error-message
                          :name="`virtualgiftNum_${index}`"
                          class="error form-error"
                        ></error-message>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="d-flex justify-content-end" v-if="form.virtualIsable === 'T'">
                  <button
                    class="btn-plus mt-3"
                    @click="addGift(false)"
                    :disabled="bonusGroupID === 'new' ? false : true"
                  >
                    <i class="fas fa-plus-circle"></i>
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-4 mt-3">
                <button
                  type="submit"
                  class="save btn btn-main px-4"
                  v-if="new Date(actInfo.endDate) > new Date() || bonusGroupID === 'new'"
                >
                  儲存
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      staffId: '',
      bonusGroupID: '',
      actlist: '',
      actInfo: {
        activityID: '',
        startDate: '',
        endDate: '',
        createdAt: '',
        exchangeEndDate: '',
      },
      giftlist: {
        real: '',
        virtual: '',
      },
      form: {
        activityID: '',
        virtualIsable: 'F',
        virtualGifts: [
          {
            giftId: '',
            giftNum: '',
            giftUrl: '',
            bonusPoint: '',
            bonusTimes: '',
          },
        ],
        realIsable: 'F',
        realGifts: [
          {
            giftId: '',
            giftNum: '',
            bonusPoint: '',
            bonusTimes: '',
          },
        ],
      },
    };
  },
  methods: {
    upload(index, e) {
      const vm = this;
      const file = e.target.files[0];
      vm.form.virtualGifts[index].giftUrl = file;
    },
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      const regx = /\d+/u;
      for (let i = 0; i < keys.length; i += 1) {
        if (keys[i] === 'virtualGifts') {
          const data = Object.values(object[keys[i]]);
          for (let j = 0; j < data.length; j += 1) {
            const info = Object.values(data[j]);
            formData.append('giftId[]', info[0]);
            formData.append('giftNum[]', info[1]);
            formData.append('bonusPoint[]', info[2]);
            formData.append('bonusTimes[]', info[3]);
            if (regx.test(info[4])) {
              formData.append('giftUrl[]', '-');
              formData.append('prizeId[]', info[4]);
            } else {
              formData.append('giftUrl[]', info[4]);
              formData.append('prizeId[]', '-');
            }
          }
        } else {
          formData.append(keys[i], object[keys[i]]);
        }
      }
      return formData;
    },
    convert(str) {
      // 日期格式轉換
      const date = new Date(str);
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      return [date.getFullYear(), mnth, day].join('/');
    },
    convertTime(str) {
      // 日期 & 時間格式轉換
      const date = new Date(str.replace(/\s/, 'T'));
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      const hours = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);
      // const seconds = (`0${date.getSeconds()}`).slice(-2);
      return `${[date.getFullYear(), mnth, day].join('/')} ${[hours, minutes].join(':')} `;
    },
    act() {
      // 導入活動選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actPrizeInfo`;
      const formData = new FormData();
      formData.append('prizeType', 'bonus');
      vm.$http
        .post(api, formData)
        .then((res) => {
          if (res.data.status === 200) {
            vm.actlist = res.data.activity;
          } else {
            Swal.fire({
              icon: 'warning',
              html: '查無活動選單',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    actDate(activityId) {
      // 領獎期限
      const vm = this;
      vm.actlist.forEach((e) => {
        if (Number(activityId) === e.activityID) {
          if (e.endDate !== null) {
            vm.actInfo.endDate = vm.convertTime(e.endDate);
            vm.actInfo.startDate = vm.convertTime(e.startDate);
            vm.actInfo.exchangeEndDate = vm.convertTime(e.exchangeEndDate);
          } else {
            vm.actInfo.endDate = '';
            vm.actInfo.startDate = '';
            vm.actInfo.exchangeEndDate = '';
          }
        }
      });
    },
    gift() {
      // 導入獎品選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/gift/giftTypeGroup`;
      vm.$http
        .post(api)
        .then((res) => {
          vm.giftlist.real = res.data.data.real;
          vm.giftlist.virtual = res.data.data.virtual;
          const newRealgiftList = [];
          const newVirtualgiftList = [];
          for (let i = 0; i < Object.keys(vm.giftlist.real).length; i += 1) {
            const key = Object.keys(vm.giftlist.real)[i];
            const value = Object.values(vm.giftlist.real)[i];
            newRealgiftList.push({ giftId: key, giftName: value });
          }
          for (let i = 0; i < Object.keys(vm.giftlist.virtual).length; i += 1) {
            const key = Object.keys(vm.giftlist.virtual)[i];
            const value = Object.values(vm.giftlist.virtual)[i];
            newVirtualgiftList.push({ giftId: key, giftName: value });
          }
          vm.giftlist.real = newRealgiftList;
          vm.giftlist.virtual = newVirtualgiftList;
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    editForm() {
      // 載入先前已編輯過表單資料
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardBonusGetDetail`;
      if (vm.bonusGroupID !== 'new') {
        vm.$http.post(api, { groupID: vm.bonusGroupID }).then((res) => {
          if (res.data.status === '200') {
            if (vm.actInfo.activityID !== '0') {
              vm.form.activityID = vm.actInfo.activityID;
            } else {
              vm.form.activityID = '';
            }
            vm.actInfo.endDate = vm.convertTime(res.data.data.endDate);
            vm.actInfo.startDate = vm.convertTime(res.data.data.startDate);
            vm.actInfo.createdAt = vm.convertTime(res.data.data.createdAt);
            vm.actInfo.exchangeEndDate = vm.convertTime(res.data.data.exchangeEndDate);
            vm.form.bonusGroupID = vm.groupID;
            vm.form.virtualIsable = res?.data?.data?.virtualIsable === 'T' ? 'T' : 'F';
            vm.form.realIsable = res?.data?.data?.realIsable === 'T' ? 'T' : 'F';

            if (vm.form.virtualIsable === 'T') {
              const virtualgifts = res.data.data.virtualGifts;
              for (let i = 0; i < virtualgifts.length; i += 1) {
                if (i === 0) {
                  // 第一個為預設空值，所以用取代
                  vm.form.virtualGifts[i].giftId = res.data.data.virtualGifts[i].giftId;
                  vm.form.virtualGifts[i].giftNum = res.data.data.virtualGifts[i].giftNum;
                  vm.form.virtualGifts[i].prizeId = res.data.data.virtualGifts[i].prizeID;
                  vm.form.virtualGifts[i].giftUrl = '-';
                  vm.form.virtualGifts[i].bonusPoint = res.data.data.virtualGifts[i].bonusPoint;
                  vm.form.virtualGifts[i].bonusTimes = res.data.data.virtualGifts[i].bonusTimes;
                } else {
                  vm.form.virtualGifts.push({
                    // 第二個開始用push
                    giftId: res.data.data.virtualGifts[i].giftId,
                    giftNum: res.data.data.virtualGifts[i].giftNum,
                    prizeId: res.data.data.virtualGifts[i].prizeID,
                    giftUrl: '-',
                    bonusPoint: res.data.data.virtualGifts[i].bonusPoint,
                    bonusTimes: res.data.data.virtualGifts[i].bonusTimes,
                  });
                }
              }
            }
            if (vm.form.realIsable === 'T') {
              const realgifts = res.data.data.realGifts;
              for (let i = 0; i < realgifts.length; i += 1) {
                if (i === 0) {
                  vm.form.realGifts[i].giftNum = res.data.data.realGifts[i].giftNum;
                  vm.form.realGifts[i].prizeId = res.data.data.realGifts[i].prizeID;
                  vm.form.realGifts[i].giftId = res.data.data.realGifts[i].giftId;
                  vm.form.realGifts[i].bonusPoint = res.data.data.realGifts[i].bonusPoint;
                  vm.form.realGifts[i].bonusTimes = res.data.data.realGifts[i].bonusTimes;
                } else {
                  vm.form.realGifts.push({
                    giftNum: res.data.data.realGifts[i].giftNum,
                    prizeId: res.data.data.realGifts[i].prizeID,
                    giftId: res.data.data.realGifts[i].giftId,
                    bonusPoint: res.data.data.realGifts[i].bonusPoint,
                    bonusTimes: res.data.data.realGifts[i].bonusTimes,
                  });
                }
              }
            }
          }
        });
      }
    },
    addGift(isReal) {
      const vm = this;
      if (isReal) {
        if (vm.form.realGifts.length < 10) {
          vm.form.realGifts.push({
            giftId: '',
            giftNum: '',
            bonusPoint: '',
            bonusTimes: '',
          });
        } else {
          Swal.fire({
            icon: 'error',
            html: '已達獎品新增上限<br>獎品新增上限為十筆',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (vm.form.virtualGifts.length < 10) {
          vm.form.virtualGifts.push({
            giftId: '',
            giftNum: '',
            giftUrl: '',
            bonusPoint: '',
            bonusTimes: '',
          });
        } else {
          Swal.fire({
            icon: 'error',
            html: '已達獎品新增上限<br>獎品新增上限為十筆',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      }
    },
    delGift(item, isReal) {
      const vm = this;
      if (isReal) {
        const index = vm.form.realGifts.indexOf(item);
        if (index !== -1) {
          vm.form.realGifts.splice(index, 1);
        }
      } else {
        const index = vm.form.virtualGifts.indexOf(item);
        if (index !== -1) {
          vm.form.virtualGifts.splice(index, 1);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) {
      // 送出表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardBonusprizeDetailProcess`;
      const postData = JSON.parse(JSON.stringify(vm.form));
      postData.realGifts = JSON.stringify(postData.realGifts);
      for (let i = 0; i < postData.virtualGifts.length; i += 1) {
        delete postData.virtualGifts[i].giftUrl;
        if (vm.form.virtualGifts[i].giftUrl !== '-') {
          postData.virtualGifts[i].giftUrl = vm.form.virtualGifts[i].giftUrl;
        }
      }

      if (typeof vm.form.activtyName !== 'undefined') {
        delete postData.activityName;
      }
      if (this.bonusGroupID !== 'new') {
        postData.groupID = this.bonusGroupID;
      }
      vm.$http
        .post(api, vm.getFormData(postData))
        .then((res) => {
          if (res.data.status === 200) {
            window.location.replace(`/admin/bonus/${vm.staffId}`);
          } else {
            Swal.fire({
              icon: 'error',
              html: '請再次確認表單內容是否有誤<br>謝謝',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    validate(value) {
      // 表單驗證
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.actInfo.activityID = this.$route.params.activityID;
    this.bonusGroupID = this.$route.params.bonusGroupID;
    this.editForm();
    this.act();
    this.gift();
  },
};
</script>
