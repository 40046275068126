<template>
  <div>
    <h1 class="title m-4">資料管理－通路管理</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input
              type="text"
              class="form-control mr-1"
              name="keyword"
              id="keyword"
              placeholder="輸入通路名稱"
              v-model.trim="keyword"
            />
            <button class="btn-main btn searching" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢
            </button>
          </div>
        </div>
      </form>
      <div class="add text-right d-flex">
        <button class="btn btn-main" @click="updateStore(true, '')">＋新增通路</button>
      </div>
    </div>
    <div class="container">
      <div class="page">
        <div class="select-perpage d-flex justify-content-end align-items-center">
          <span>每頁顯示</span>
          <select class="form-select" v-model.number="perpage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <span>筆</span>
        </div>
      </div>
      <table class="table table-hover mt-4">
        <thead class="bg-thead">
          <tr>
            <th width="320" class="text-center" @click="changeType('store')">
              通路名稱
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'store'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="360" class="text-center" @click="changeType('companyName')">
              發票名稱
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'companyName'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="320" class="text-center" @click="changeType('matchName')">
              比對名稱
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'matchName'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center">狀態</th>
            <th width="180" class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in displayRecords()" :key="index">
            <td class="text-center">{{ item.store }}</td>
            <td class="text-center">{{ item.companyName }}</td>
            <td class="text-center">{{ item.matchName }}</td>
            <td class="text-center">
              <div class="form-check form-switch d-flex justify-content-center">
                <input
                  class="text-center form-check-input"
                  type="checkbox"
                  v-model="item.status"
                  @change="switchStatus(item.storeId, item.status)"
                />
              </div>
            </td>
            <td class="text-center">
              <button class="btn btn-table btn-success btn-sm" @click="updateStore(false, item)">
                <i class="fas fa-pen"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end">
        <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
        />
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="storeModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">新增通路</h5>
          <button type="button" class="close" @click="closeModal()">
            <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
          </button>
        </div>
        <div class="modal-body m-4">
          <Form @submit="onSubmit">
            <div class="mb-5 row">
              <label for="inputStore" class="col-sm-3 col-form-label">通路名稱</label>
              <div class="col-sm-7">
                <Field
                  :rules="validate"
                  :readonly="!New"
                  as="input"
                  type="text"
                  class="form-control"
                  name="store"
                  id="inputStore"
                  v-model.trim="storeDetails.store"
                />
              </div>
              <div class="col-sm-2">
                <error-message name="store" class="error form-error"> </error-message>
              </div>
            </div>
            <div class="mb-5 row">
              <label for="inputInvoice" class="col-sm-3 col-form-label">發票名稱</label>
              <div class="col-sm-7">
                <Field
                  :rules="validate"
                  :readonly="!New"
                  as="input"
                  type="text"
                  class="form-control"
                  name="inputInvoice"
                  id="inputInvoice"
                  v-model.trim="storeDetails.companyName"
                />
              </div>
              <div class="col-sm-2">
                <error-message name="inputInvoice" class="error form-error"> </error-message>
              </div>
            </div>
            <div class="mb-5 row">
              <label for="inputMatch" class="col-sm-3 col-form-label">比對名稱</label>
              <div class="col-sm-7">
                <Field
                  :rules="validate"
                  :readonly="!New"
                  as="input"
                  type="text"
                  class="form-control"
                  name="inputMatch"
                  id="inputMatch"
                  v-model.trim="storeDetails.matchName"
                />
              </div>
              <div class="col-sm-2">
                <error-message name="inputMatch" class="error form-error"> </error-message>
              </div>
            </div>
            <div class="mb-5 row">
              <label for="inputStatus" class="col-sm-3">狀態</label>
              <div class="col-sm-9">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="T"
                    v-model="storeDetails.status"
                    checked
                  />
                  <label class="form-check-label" for="inlineRadio1">啟用</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="F"
                    v-model="storeDetails.status"
                  />
                  <label class="form-check-label" for="inlineRadio2">關閉</label>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-accent d-block m-auto">儲存</button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      staffId: '',
      keyword: '',
      tempKeyword: '',
      tableData: '',
      storeDetails: {
        status: 'T',
      },
      New: false,
      pageSize: '',
      perpage: 30,
      page: 1,
      sortType: 'store',
      isReverse: true,
    };
  },
  methods: {
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    search() {
      // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/shop/shopDetail`;
      vm.tempKeyword = vm.keyword;
      const formData = new FormData();
      formData.append('keyword', vm.tempKeyword);
      vm.$http
        .post(api, formData)
        .then((res) => {
          if (res.data.status === 200) {
            vm.tableData = res.data.data;
            for (let i = 0; i < vm.tableData.length; i += 1) {
              // 開啟關閉狀態value轉換為Boolean
              if (vm.tableData[i].status === 'T') {
                vm.tableData[i].status = true;
              } else {
                vm.tableData[i].status = false;
              }
            }
          } else {
            vm.tableData = [];
            Swal.fire({
              icon: 'warning',
              text: '查無相關資訊',
              confirmButtonColor: '#ec7805',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          vm.pageSize = vm.tableData.length;
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    switchStatus(storeId, status) {
      // switch開關狀態
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/shop/shopDetailStatus`;
      if (status === true) {
        // 開啟關閉狀態value轉換為"T" "F"
        // eslint-disable-next-line no-param-reassign
        status = 'T';
      } else {
        // eslint-disable-next-line no-param-reassign
        status = 'F';
      }
      vm.$http.post(api, vm.getFormData({ storeId, status })).then((res) => {
        if (res.data.status === 200) {
          vm.search();
        }
      });
    },
    updateStore(New, details) {
      // 新增或編輯通路
      const vm = this;
      $('#storeModal').modal('show');
      if (New) {
        // eslint-disable-next-line no-param-reassign
        details = {
          status: 'T',
          store: '',
          companyName: '',
          matchName: '',
        };
        vm.storeDetails = { ...details };
        vm.New = true;
      } else {
        vm.storeDetails = { ...details };
        if (vm.storeDetails.status === true) {
          vm.storeDetails.status = 'T';
        } else {
          vm.storeDetails.status = 'F';
        }
        vm.New = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) {
      // 送出表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/shop/shopDetailProcess`;
      vm.$http
        .post(api, vm.getFormData(vm.storeDetails))
        .then((res) => {
          if (res.data.status === 200) {
            $('#storeModal').modal('hide');
            vm.search();
          } else {
            $('#storeModal').modal('hide');
            Swal.fire({
              icon: 'error',
              html: '通路更新失敗<br>請重新新增或編輯通路',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          vm.pageSize = vm.tableData.length;
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    validate(value) {
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
    closeModal() {
      // 關閉 Modal
      $('#storeModal').modal('hide');
    },
    // delModal(details) { // show delete modal
    //   const vm = this;
    //   vm.storeDetails = details;
    //   $('#delStoreModal').modal('show');
    // },
    // delConfirm(storeId) { // 確認刪除 button
    //   const vm = this;
    //   const api = `${process.env.VUE_APP_URL}api/shop/shopDetailStatus`;
    //   vm.$http.post(api, vm.getFormData({ storeId })).then((res) => {
    //     if (res.data.status === 200) {
    //       console.log('刪除成功');
    //       vm.search();
    //     } else {
    //       console.log(res.data.status);
    //     }
    //     $('#delStoreModal').modal('hide');
    //   }).catch((error) => {
    //     console.log(error);
    //     $('#delStoreModal').modal('hide');
    //   });
    // },
    // cancelModal() { // 取消刪除 button
    //   $('#delStoreModal').modal('hide');
    // },
    // eslint-disable-next-line no-unused-vars
    callback(page) {
      // 分頁
    },
    displayRecords() {
      // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) {
      // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() {
      // 排序Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        let A;
        let B;
        if (type === 'uploadNum' || type === 'matchNum') {
          A = a.memberDetails[type];
          B = b.memberDetails[type];
        } else {
          A = a[type];
          B = b[type];
        }
        if (vm.isReverse) {
          if (A === null || B === null) {
            if (A === null && B !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (A !== null && B === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (B > A) {
                return -1;
              }
              if (A > B) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return A.localeCompare(B, 'zh-Hant');
            }
          }
          // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (A === null || B === null) {
            if (B === null && A !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (B !== null && A === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (A > B) {
                return -1;
              }
              if (B > A) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return B.localeCompare(A, 'zh-Hant');
            }
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
};
</script>
