<template>
  <div>
    <h1 class="title m-4">新增活動</h1>
    <div class="form">
      <div class="addformContent border m-4">
        <div class="form-title">
          <h4>新增活動</h4>
        </div>
        <Form @submit="onSubmit">
          <div class="container mb-5 pb-5">
            <div class="row clearfix">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">活動名稱</h5>
                    <error-message name="activityName" class="error form-error"></error-message>
                    <Field
                      :rules="validate"
                      as="input"
                      type="text"
                      class="form-control"
                      name="activityName"
                      v-model.trim="form.activityName"
                      :disabled="
                        new Date(form.activityStart) > new Date() || activityID === 'new'
                          ? false
                          : true
                      "
                    />
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">發票關鍵字</h5>
                    <error-message name="keywords" class="error form-error"></error-message>
                    <div class="input-g d-flex align-items-center">
                      <Field
                        :rules="validate"
                        as="input"
                        type="text"
                        class="form-control"
                        name="keywords"
                        v-model.trim="form.keyWords"
                        :disabled="
                          new Date(form.activityStart) > new Date() || activityID === 'new'
                            ? false
                            : true
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">登錄發票金額（單張發票)</h5>
                    <error-message name="singleEntryTotal" class="error form-error">
                    </error-message>
                    <div class="input-g d-flex align-items-center">
                      <Field
                        :rules="validate"
                        as="input"
                        type="text"
                        class="form-control"
                        name="singleEntryTotal"
                        placeholder="輸入金額"
                        v-model.trim="form.singleEntryTotal"
                        :disabled="
                          new Date(form.activityStart) > new Date() || activityID === 'new'
                            ? false
                            : true
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-md-6 mt-3 calendar">
                    <h5 class="d-inline-block">活動起始日</h5>
                    <error-message name="activityStart" class="error form-error"></error-message>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="validate"
                        as="vcalendar"
                        name="activityStart"
                        v-model="form.activityStart"
                      />
                      <vcalendar
                        v-model="form.activityStart"
                        :maxDate="disableStartDate(form.activityEnd)"
                        @change="setDefault2other = false"
                        :Default="setDefault2other"
                        :defaultDate="convert(form.activityStart)"
                        :disable="
                          new Date(form.activityStart) > new Date() || activityID === 'new'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3 calendar">
                    <h5 class="d-inline-block">活動結束日</h5>
                    <error-message name="activityEnd" class="error form-error"></error-message>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="validate"
                        as="vcalandar"
                        name="activityEnd"
                        v-model="form.activityEnd"
                      />
                      <vcalendar
                        v-model="form.activityEnd"
                        :minDate="disableEndDate(form.activityStart)"
                        @change="setDefault2other = false"
                        :Default="setDefault2other"
                        :defaultDate="convert(form.activityEnd)"
                        :disable="
                          new Date(form.activityStart) > new Date() || activityID === 'new'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3 calendar">
                    <h5 class="d-inline-block">發票起始日</h5>
                    <error-message name="entryStartDate" class="error form-error"></error-message>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="validate"
                        as="vcalendar"
                        name="entryStartDate"
                        v-model="form.entryStartDate"
                      />
                      <vcalendar
                        v-model="form.entryStartDate"
                        :maxDate="disableStartDate(form.entryEndDate)"
                        @change="setDefault2other = false"
                        :Default="setDefault2other"
                        :defaultDate="convert(form.entryStartDate)"
                        :disable="
                          new Date(form.activityStart) > new Date() || activityID === 'new'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3 calendar">
                    <h5 class="d-inline-block">發票結束日</h5>
                    <error-message name="entryEndDate" class="error form-error"></error-message>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="validate"
                        as="vcalendar"
                        name="entryEndDate"
                        v-model="form.entryEndDate"
                      />
                      <vcalendar
                        v-model="form.entryEndDate"
                        :minDate="disableEndDate(form.entryStartDate)"
                        @change="setDefault2other = false"
                        :Default="setDefault2other"
                        :defaultDate="convert(form.entryEndDate)"
                        :disable="
                          new Date(form.activityStart) > new Date() || activityID === 'new'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-4 mt-3 calendar">
                    <div class="d-flex">
                      <h5 class="d-inline-block">抽獎開獎日</h5>
                      <div class="form-check form-check-inline mx-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gift"
                          id="giftOpen"
                          value="true"
                          v-model="form.gift_isable"
                          checked
                          :disabled="
                            new Date(form.activityStart) > new Date() || activityID === 'new'
                              ? false
                              : true
                          "
                        />
                        <label class="form-check-label" for="giftOpen">開啟</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gift"
                          id="giftClose"
                          value="false"
                          v-model="form.gift_isable"
                          @change="Enable('gift')"
                          :disabled="
                            new Date(form.activityStart) > new Date() || activityID === 'new'
                              ? false
                              : true
                          "
                        />
                        <label class="form-check-label" for="giftClose">關閉</label>
                      </div>
                      <error-message name="giftDate" class="error form-error"></error-message>
                    </div>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="form.gift_isable === 'false' ? '' : validate"
                        as="vcalendar"
                        name="giftDate"
                        v-model="form.giftDate"
                      />
                      <vcalendar
                        v-model="form.giftDate"
                        :maxDate="disableStartDate(form.getGiftDate)"
                        @change="setDefault_Gift = false"
                        :Default="setDefault_Gift"
                        :defaultDate="convert(form.giftDate)"
                        :disable="
                          (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                          form.gift_isable === 'true'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-4 mt-3 calendar">
                    <h5 class="d-inline-block">抽獎領獎期限</h5>
                    <error-message name="getGiftDate" class="error form-error"></error-message>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="form.gift_isable === 'false' ? '' : validate"
                        as="vcalendar"
                        name="getGiftDate"
                        v-model="form.getGiftDate"
                      />
                      <vcalendar
                        v-model="form.getGiftDate"
                        :minDate="disableEndDate(form.giftDate)"
                        @change="setDefault_Gift = false"
                        :Default="setDefault_Gift"
                        :defaultDate="convert(form.getGiftDate)"
                        :disable="
                          (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                          form.gift_isable === 'true'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">抽獎發票金額（單張發票）</h5>
                    <error-message name="achieveTotal" class="error form-error"></error-message>
                    <Field
                      :rules="form.gift_isable === 'false' ? '' : validate"
                      as="input"
                      type="text"
                      class="form-control"
                      name="achieveTotal"
                      placeholder="輸入金額"
                      v-model.trim="form.achieveTotal"
                      :disabled="
                        (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                        form.gift_isable === 'true'
                          ? false
                          : true
                      "
                    />
                  </div>
                  <div class="col-md-4 mt-3 calendar">
                    <div class="d-flex">
                      <h5>滿額獎開獎日</h5>
                      <div class="form-check form-check-inline mx-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="priceGift"
                          id="priceGiftOpen"
                          value="true"
                          v-model="form.priceGift_isable"
                          checked
                          :disabled="
                            new Date(form.activityStart) > new Date() || activityID === 'new'
                              ? false
                              : true
                          "
                        />
                        <label class="form-check-label" for="priceGiftOpen">開啟</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="priceGift"
                          id="priceGiftClose"
                          value="false"
                          v-model="form.priceGift_isable"
                          @change="Enable('priceGift')"
                          :disabled="
                            new Date(form.activityStart) > new Date() || activityID === 'new'
                              ? false
                              : true
                          "
                        />
                        <label class="form-check-label" for="priceGiftClose">關閉</label>
                      </div>
                      <error-message name="priceGiftDate" class="error form-error"></error-message>
                    </div>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="form.priceGift_isable === 'false' ? '' : validate"
                        as="vcalendar"
                        name="priceGiftDate"
                        v-model="form.priceGiftDate"
                      />
                      <vcalendar
                        v-model="form.priceGiftDate"
                        :maxDate="disableStartDate(form.getpriceGiftDate)"
                        @change="setDefault_Price = false"
                        :Default="setDefault_Price"
                        :defaultDate="convert(form.priceGiftDate)"
                        :disable="
                          (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                          form.priceGift_isable === 'true'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-4 mt-3 calendar">
                    <h5 class="d-inline-block">滿額獎領獎期限</h5>
                    <error-message name="getpriceGiftDate" class="error form-error"></error-message>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="form.priceGift_isable === 'false' ? '' : validate"
                        as="vcalendar"
                        name="getpriceGiftDate"
                        v-model="form.getpriceGiftDate"
                      />
                      <vcalendar
                        v-model="form.getpriceGiftDate"
                        :minDate="disableEndDate(form.priceGiftDate)"
                        @change="setDefault_Price = false"
                        :Default="setDefault_Price"
                        :defaultDate="convert(form.getpriceGiftDate)"
                        :disable="
                          (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                          form.priceGift_isable === 'true'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">滿額贈金額（所有合格發票加總）</h5>
                    <error-message name="achievePriceTotal" class="error form-error">
                    </error-message>
                    <Field
                      :rules="form.priceGift_isable === 'false' ? '' : validate"
                      as="input"
                      type="text"
                      class="form-control"
                      name="achievePriceTotal"
                      placeholder="輸入金額"
                      v-model.trim="form.achievePriceTotal"
                      :disabled="
                        (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                        form.priceGift_isable === 'true'
                          ? false
                          : true
                      "
                    />
                  </div>
                  <!-- 紅利獎 -->
                  <div class="col-md-4 mt-3 calendar">
                    <div class="d-flex">
                      <h5>紅利獎可兌換日</h5>
                      <div class="form-check form-check-inline mx-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="bonus"
                          id="bonusOpen"
                          value="true"
                          v-model="form.bonus_isable"
                          checked
                          :disabled="
                            new Date(form.activityStart) > new Date() || activityID === 'new'
                              ? false
                              : true
                          "
                        />
                        <label class="form-check-label" for="bonusOpen">開啟</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="bonus"
                          id="bonusClose"
                          value="false"
                          v-model="form.bonus_isable"
                          @change="Enable('bonus')"
                          :disabled="
                            new Date(form.activityStart) > new Date() || activityID === 'new'
                              ? false
                              : true
                          "
                        />
                        <label class="form-check-label" for="bonusClose">關閉</label>
                      </div>
                      <error-message name="bonusDate" class="error form-error"></error-message>
                    </div>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="form.bonus_isable === 'false' ? '' : validate"
                        as="vcalendar"
                        name="bonusDate"
                        v-model="form.bonusDate"
                      />
                      <vcalendar
                        v-model="form.bonusDate"
                        :maxDate="disableStartDate(form.getBonusDate)"
                        @change="setDefault_Price = false"
                        :Default="setDefault_Price"
                        :defaultDate="convert(form.bonusDate)"
                        :disable="
                          (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                          form.bonus_isable === 'true'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-4 mt-3 calendar">
                    <h5 class="d-inline-block">紅利獎領獎期限</h5>
                    <error-message name="getBonusDate" class="error form-error"></error-message>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="form.bonus_isable === 'false' ? '' : validate"
                        as="vcalendar"
                        name="getBonusDate"
                        v-model="form.getBonusDate"
                      />
                      <vcalendar
                        v-model="form.getBonusDate"
                        :minDate="disableEndDate(form.bonusDate)"
                        @change="setDefault_Price = false"
                        :Default="setDefault_Price"
                        :defaultDate="convert(form.getBonusDate)"
                        :disable="
                          (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                          form.bonus_isable === 'true'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">紅利獎發票金額對應點數</h5>
                    <error-message name="redeem" class="error form-error"> </error-message>
                    <Field
                      :rules="form.bonus_isable === 'false' ? '' : validate"
                      as="input"
                      type="text"
                      class="form-control"
                      name="redeem"
                      placeholder="輸入金額"
                      v-model.trim="form.redeem"
                      :disabled="
                        (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                        form.bonus_isable === 'true'
                          ? false
                          : true
                      "
                    />
                  </div>
                  <div class="col-md-4 mt-3 calendar">
                    <h5 class="d-inline-block">紅利獎兌換期限</h5>
                    <error-message name="exchangeDate" class="error form-error"></error-message>
                    <div class="dateSelect d-flex">
                      <Field
                        :rules="form.bonus_isable === 'false' ? '' : validate"
                        as="vcalendar"
                        name="exchangeDate"
                        v-model="form.exchangeDate"
                      />
                      <vcalendar
                        v-model="form.exchangeDate"
                        :minDate="disableEndDate(form.bonusDate)"
                        @change="setDefault_Price = false"
                        :Default="setDefault_Price"
                        :defaultDate="convert(form.exchangeDate)"
                        :disable="
                          (new Date(form.activityStart) > new Date() || activityID === 'new') &&
                          form.bonus_isable === 'true'
                            ? false
                            : true
                        "
                      />
                      <span><i class="fas fa-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <h5 class="d-inline-block">適用通路</h5>
                    <error-message name="stores" class="error form-error"> </error-message>
                    <Field :rules="validate" name="stores" v-model="form.stores">
                      <Select2
                        :options="shoplist"
                        :settings="{
                          multiple: 'multiple',
                          tokenSeparators: [',', ' '],
                        }"
                        v-model="form.stores"
                        :disabled="
                          new Date(form.activityStart) > new Date() || activityID === 'new'
                            ? false
                            : true
                        "
                    /></Field>
                  </div>
                  <div class="col-md-12 mt-3">
                    <h5 class="d-inline-block">適用商品</h5>
                    <error-message name="items" class="error form-error"> </error-message>
                    <Field :rules="validate" name="items" v-model="form.items">
                      <Select2
                        :options="itemlist"
                        :settings="{
                          multiple: 'multiple',
                          tokenSeparators: [',', ' '],
                        }"
                        v-model="form.items"
                        :disabled="
                          new Date(form.activityStart) > new Date() || activityID === 'new'
                            ? false
                            : true
                        "
                    /></Field>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 mt-3">
                <button
                  type="submit"
                  class="save btn btn-main px-4"
                  v-if="new Date(form.activityStart) > new Date() || activityID === 'new'"
                >
                  儲存
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from 'vue3-select2-component';
import Swal from 'sweetalert2';
import vcalendar from '../components/V-Calendar.vue';

export default {
  components: {
    vcalendar,
    Select2,
  },
  data() {
    return {
      staffId: '',
      setDefault_Gift: true,
      setDefault_Price: true,
      setDefault2other: true,
      newTag: true,
      startDate: '',
      endDate: '',
      shoplist: [],
      itemlist: [],
      activityID: '',
      form: {
        gift_isable: 'true',
        priceGift_isable: 'true',
        bonus_isable: 'true',
      },
    };
  },
  methods: {
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) {
      // 日期格式轉換
      const date = new Date(str);
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      const hours = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) {
        return '';
        // eslint-disable-next-line no-else-return
      } else {
        // eslint-disable-next-line prefer-template
        return [date.getFullYear(), mnth, day].join('/') + ' ' + hours + ':' + minutes;
      }
    },
    // eslint-disable-next-line consistent-return
    disableStartDate(endDate) {
      // 可選擇日期 >startDate
      if (endDate) {
        return new Date(this.convert(endDate));
      }
    },
    // eslint-disable-next-line consistent-return
    disableEndDate(startDate) {
      // 可選擇日期 < endDate
      if (startDate) {
        return new Date(this.convert(startDate));
      }
    },
    editForm() {
      // 載入先前已編輯過表單資料
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actDetailEdit`;
      const formData = new FormData();
      formData.append('activityID', vm.activityID);
      if (vm.activityID !== 'new') {
        vm.$http.post(api, formData).then((res) => {
          if (res.data.status === 200) {
            vm.form = res.data.data;
            const store = [];
            for (let i = 0; i < vm.form.shops.length; i += 1) {
              store.push(Object.keys(vm.form.shops[i])[0]);
            }
            const item = [];
            for (let i = 0; i < vm.form.items.length; i += 1) {
              item.push(Object.keys(vm.form.items[i])[0]);
            }
            vm.form.stores = store;
            vm.form.items = item;
            if (vm.form.gift_isable === 'false') {
              vm.form.giftDate = '';
              vm.form.getGiftDate = '';
              vm.form.achieveTotal = '';
            }
            if (vm.form.priceGift_isable === 'false') {
              vm.form.priceGiftDate = '';
              vm.form.getpriceGiftDate = '';
              vm.form.achievePriceTotal = '';
            }

            if (vm.form.bonus_isable === 'false') {
              vm.form.bonusDate = '';
              vm.form.getBonusDate = '';
              vm.form.exchangeDate = '';
              vm.form.redeem = '';
            }
          }
        });
      }
    },
    Enable(string) {
      // 判斷抽獎與滿額獎關閉則回傳空字串
      const vm = this;
      if (string === 'gift') {
        if (vm.form.gift_isable === 'false') {
          vm.form.giftDate = '';
          vm.form.getGiftDate = '';
          vm.form.achieveTotal = '';
        }
      }

      if (string === 'priceGift') {
        // eslint-disable-next-line no-lonely-if
        if (vm.form.priceGift_isable === 'false') {
          vm.form.priceGiftDate = '';
          vm.form.getpriceGiftDate = '';
          vm.form.achievePriceTotal = '';
        }
      }

      if (string === 'bonus') {
        // eslint-disable-next-line no-lonely-if
        if (vm.form.bonus_isable === 'false') {
          vm.form.bonusDate = '';
          vm.form.getBonusDate = '';
          vm.form.exchangeDate = '';
          vm.form.redeem = '';
        }
      }
    },
    shop() {
      // 導入通路選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/shop/shopList`;
      vm.$http
        .post(api)
        .then((res) => {
          if (res.data.status === 200) {
            vm.shoplist = res.data.data;
            const newShopList = [];
            for (let i = 0; i < vm.shoplist.length; i += 1) {
              const key = Object.keys(vm.shoplist[i])[0];
              const value = vm.shoplist[i][key];
              newShopList.push({ id: key, text: value });
            }
            vm.shoplist = newShopList;
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    item() {
      // 導入品項選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/dataManager/itemList`;
      vm.$http
        .post(api)
        .then((res) => {
          if (res.data.status === 200) {
            vm.itemlist = res.data.data;
            const newItemList = [];
            vm.itemlist.forEach((e) => {
              const key = Object.keys(e)[0];
              const value = e[key];
              newItemList.push({ id: key, text: value });
            });
            vm.itemlist = newItemList;
            // console.log(vm.itemlist);
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) {
      // 送出表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actDetailProcess`;
      if (vm.activityID !== 'new') {
        vm.form.activityID = vm.activityID;
        delete vm.form.shops;
      }
      vm.form.activityStart = vm.convert(vm.form.activityStart);
      vm.form.activityEnd = vm.convert(vm.form.activityEnd);
      vm.form.entryStartDate = vm.convert(vm.form.entryStartDate);
      vm.form.entryEndDate = vm.convert(vm.form.entryEndDate);
      if (vm.form.giftDate === null) {
        vm.form.giftDate = '';
      } else {
        vm.form.giftDate = vm.convert(vm.form.giftDate);
      }
      if (vm.form.getGiftDate === null) {
        vm.form.getGiftDate = '';
      } else {
        vm.form.getGiftDate = vm.convert(vm.form.getGiftDate);
      }
      if (vm.form.priceGiftDate === null) {
        vm.form.priceGiftDate = '';
      } else {
        vm.form.priceGiftDate = vm.convert(vm.form.priceGiftDate);
      }
      if (vm.form.getpriceGiftDate === null) {
        vm.form.getpriceGiftDate = '';
      } else {
        vm.form.getpriceGiftDate = vm.convert(vm.form.getpriceGiftDate);
      }
      if (vm.form.bonusDate === null) {
        vm.form.bonusDate = '';
      } else {
        vm.form.bonusDate = vm.convert(vm.form.bonusDate);
      }
      if (vm.form.getBonusDate === null) {
        vm.form.getBonusDate = '';
      } else {
        vm.form.getBonusDate = vm.convert(vm.form.getBonusDate);
      }
      if (vm.form.exchangeDate === null) {
        vm.form.exchangeDate = '';
      } else {
        vm.form.exchangeDate = vm.convert(vm.form.exchangeDate);
      }
      vm.form.stores = JSON.stringify(vm.form.stores);
      vm.form.items = JSON.stringify(vm.form.items);
      vm.$http
        .post(api, vm.getFormData(vm.form))
        .then((res) => {
          if (res.data.status === 200) {
            vm.$router.push(`/admin/activity/${vm.staffId}`);
          } else if (res.data.status === 400) {
            Swal.fire({
              icon: 'error',
              title: '此活動起訖日期已被設定',
              text: '請重新選擇活動起始日與活動結束日',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: '活動更新失敗',
              text: '請再次確認表單是否正確填寫',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    validate(value) {
      // 表單驗證
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.activityID = this.$route.params.activityID;
    this.editForm();
    this.shop();
    this.item();
  },
};
</script>
