/* eslint-disable import/extensions */
<template>
  <div class="main">
    <div class="container-fluid">
      <Sidebar></Sidebar>

      <div class="content">
        <div class="container">
          <!-- <router-view v-if="!$route.meta?.keepAlive" /> -->
          <router-view v-slot="{ Component, route }">
            <keep-alive :exclude="$route?.meta?.exclude">
              <component v-if="$route.meta?.keepAlive" :key="route.name" :is="Component" />
            </keep-alive>
            <component :is="Component" :key="route.name" v-if="!$route.meta?.keepAlive" />
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';

export default {
  components: {
    Sidebar,
  },
};
</script>
