<template>
  <div>
    <h1 class="title m-4">贈獎管理－抽獎設定</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input
              type="text"
              class="form-control mr-1"
              name="keyword"
              id="keyword"
              placeholder="輸入活動名稱"
              v-model.trim="keyword"
            />
            <button class="btn-main btn searching" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢
            </button>
          </div>
        </div>
      </form>
      <div class="add text-right d-flex">
        <button class="btn-main btn" @click="toEdit(0, 'new')">＋新增抽獎</button>
      </div>
    </div>
    <div class="container">
      <div class="page">
        <div class="select-perpage d-flex justify-content-end align-items-center">
          <span>每頁顯示</span>
          <select class="form-select" v-model.number="perpage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <span>筆</span>
        </div>
      </div>
      <table class="table table-hover mt-4">
        <thead class="bg-thead">
          <tr>
            <th width="240" class="text-center" @click="changeType('createdAt')">
              抽獎建立時間
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'createdAt'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('activityName')">
              活動名稱
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'activityName'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('startDate')">
              開獎日
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'startDate'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('endDate')">
              領獎期限
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'endDate'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('totalGifts')">
              獎品總數量
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'totalGifts'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="240" class="text-center" @click="changeType('winPeopleNum')">
              得獎人數
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'winPeopleNum'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center">中獎名單</th>
            <th class="text-center">立即抽獎</th>
            <th class="text-center">發送通知</th>
            <th width="180" class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in displayRecords()" :key="index">
            <td class="text-center">{{ item.createdAt }}</td>
            <td class="text-center">{{ item.activityName }}</td>
            <td class="text-center">{{ item.startDate }}</td>
            <td class="text-center">{{ item.endDate }}</td>
            <td class="text-center">{{ toThousands(item.totalGifts) }}</td>
            <td class="text-center">{{ toThousands(item.winPeopleNum) }}</td>
            <td class="text-center">
              <button
                class="btn-table btn-main btn btn-sm"
                @click="toWinnerList(item.activityID, item.lotteryGroupID, item.status)"
                v-if="item.status === 'B' || item.status === 'C'"
              >
                <i class="far fa-file-alt"></i>
              </button>
            </td>
            <td class="text-center">
              <button
                class="btn-table btn-success btn btn-sm"
                @click="openRaffleModal(item)"
                v-if="item.status === 'A' || item.status === 'B'"
              >
                <i class="fas fa-archive"></i>
              </button>
            </td>
            <td class="text-center">
              <button
                class="btn-table btn-accent btn btn-sm"
                @click="openMsgModal(item)"
                v-if="item.status === 'B'"
              >
                <i class="far fa-envelope"></i>
              </button>
            </td>
            <td>
              <button
                class="btn-table btn-success btn btn-sm"
                @click="toEdit(item.activityID, item.lotteryGroupID)"
                v-if="item.status === 'A'"
              >
                <i class="fas fa-pen"></i>
              </button>
              <button
                class="btn-table btn-danger btn btn-sm"
                @click="delModal(item)"
                v-if="item.status === 'A' && new Date(item.startDate) > new Date()"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end">
        <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
        />
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="raffleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">立即抽獎</h5>
        </div>
        <div class="modal-body">
          發送通知前可重新抽獎，<br />但須確認得獎名單是否已排版設計或公布。
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" @click="cancelRaffleModal()">
            取消
          </button>
          <button class="btn btn-success btn-sm py-2" @click="reRaffle(lotteryDetails)" id="raffle">
            立即抽獎
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="messageModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">發送通知</h5>
        </div>
        <div class="modal-body">
          發送通知後即無法更改中獎名單或再次抽獎。
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" @click="cancelMsgModal()">
            取消
          </button>
          <button class="btn btn-accent btn-sm py-2" @click="sendMsg(lotteryDetails)" id="sendmsg">
            發送通知
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="delLotteryModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <span>刪除抽獎設定</span>
          </h5>
        </div>
        <div class="modal-body">
          是否刪除
          <strong class="text-danger"> {{ lotteryDetails.activityName }}</strong>
          (刪除後將無法恢復)。
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" @click="delCancelModal()">
            取消
          </button>
          <button class="btn btn-danger btn-sm py-2" @click="delConfirm(lotteryDetails)">
            確認刪除
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      keyword: '',
      staffId: '',
      tableData: '',
      lotteryDetails: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      sortType: 'createdAt',
      isReverse: false,
    };
  },
  methods: {
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) {
      // 日期格式轉換
      const date = new Date(str);
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) {
        return '';
        // eslint-disable-next-line no-else-return
      } else {
        return [date.getFullYear(), mnth, day].join('/');
      }
    },
    convertTime(str) {
      // 日期 & 時間格式轉換
      const date = new Date(str.replace(/\s/, 'T'));
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      const hours = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);
      // const seconds = (`0${date.getSeconds()}`).slice(-2);
      return `${[date.getFullYear(), mnth, day].join('/')} ${[hours, minutes].join(':')} `;
    },
    toThousands(num) {
      // 千分位數加逗號
      let result = '';
      let counter = 0;
      // eslint-disable-next-line no-param-reassign
      num = (num || 0).toString();
      for (let i = num.length - 1; i >= 0; i -= 1) {
        counter += 1;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i !== 0) {
          result = `,${result}`;
        }
      }
      return result;
    },
    search() {
      // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardLotteryDetail`;
      const formData = new FormData();
      formData.append('keyword', vm.keyword);
      vm.$http
        .post(api, formData)
        .then((res) => {
          if (res.data.status === 200) {
            vm.tableData = res.data.data;
            for (let i = 0; i < vm.tableData.length; i += 1) {
              vm.tableData[i].startDate = vm.convertTime(vm.tableData[i].startDate); // 開獎日時間格式轉換
              vm.tableData[i].endDate = vm.convertTime(vm.tableData[i].endDate); // 領獎期限時間格式轉換
            }
          } else {
            vm.tableData = [];
            Swal.fire({
              icon: 'warning',
              text: '查無相關資訊',
              confirmButtonColor: '#ec7805',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          vm.pageSize = vm.tableData.length;
          // eslint-disable-next-line no-unused-vars
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toWinnerList(activityID, lotteryGroupID, status) {
      // 前往中獎名單
      const vm = this;
      vm.$router.push(
        // eslint-disable-next-line
        `/admin/lottery_winnerlist/${vm.staffId}/${activityID}/${lotteryGroupID}/${status}`
      );
    },
    toEdit(activityID, lotteryGroupID) {
      // 前往新增抽獎表單
      const vm = this;
      vm.$router.push(`/admin/lottery_add/${vm.staffId}/${activityID}/${lotteryGroupID}`);
    },
    openRaffleModal(details) {
      // open 立即抽獎 modal
      const vm = this;
      vm.lotteryDetails = { ...details };
      $('#raffleModal').modal({ backdrop: 'static', keyboard: false });
      $('#raffleModal').modal('show');
    },
    cancelRaffleModal() {
      // 取消抽獎
      $('#raffleModal').modal('hide');
    },
    reRaffle(data) {
      // 立即抽獎button
      $('#raffle').attr('disabled', true);
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardLottery`;
      const formData = new FormData();
      formData.append('activityID', data.activityID);
      formData.append('lotteryGroupID', data.lotteryGroupID);
      vm.$http
        .post(api, formData)
        .then((res) => {
          $('#raffle').attr('disabled', false);
          if (res.data.status === 200) {
            $('#raffleModal').modal('hide');
            vm.search();
          } else {
            $('#raffleModal').modal('hide');
            Swal.fire({
              icon: 'error',
              html: '抽獎失敗<br>請稍候再試',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          // eslint-disable-next-line no-unused-vars
        })
        .catch(() => {
          // console.log(error);
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    openMsgModal(details) {
      // open 發送通知 modal
      const vm = this;
      vm.lotteryDetails = { ...details };
      $('#messageModal').modal({ backdrop: 'static', keyboard: false });
      $('#messageModal').modal('show');
    },
    cancelMsgModal() {
      // 取消發送通知 button
      $('#messageModal').modal('hide');
    },
    sendMsg(data) {
      // 發送通知 button
      $('#sendmsg').attr('disabled', true);
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardLotterySendNotify`;
      const formData = new FormData();
      formData.append('activityID', data.activityID);
      formData.append('lotteryGroupID', data.lotteryGroupID);
      vm.$http
        .post(api, formData)
        .then((res) => {
          $('#sendmsg').attr('disabled', false);
          if (res.data.status === 200) {
            $('#messageModal').modal('hide');
            vm.search();
          } else {
            $('#messageModal').modal('hide');
            Swal.fire({
              icon: 'error',
              html: '發送通知失敗<br>請稍候再試',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    delModal(details) {
      // open delete modal
      const vm = this;
      vm.lotteryDetails = details;
      $('#delLotteryModal').modal('show');
    },
    delCancelModal() {
      // 取消 delete button
      $('#delLotteryModal').modal('hide');
    },
    delConfirm(details) {
      // 確認刪除button
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardLotteryDelete`;
      const formData = new FormData();
      formData.append('lotteryGroupID', details.lotteryGroupID);
      vm.$http
        .post(api, formData)
        .then((res) => {
          if (res.data.status === 200) {
            $('#delLotteryModal').modal('hide');
            vm.search();
          } else {
            $('#delLotteryModal').modal('hide');
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) {
      // 分頁
    },
    displayRecords() {
      // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) {
      // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() {
      // 排序 Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        const A = a[type];
        const B = b[type];
        if (vm.isReverse) {
          if (A === null || B === null) {
            if (A === null && B !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (A !== null && B === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (B > A) {
                return -1;
              }
              if (A > B) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return A.localeCompare(B, 'zh-Hant');
            }
          }
          // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (A === null || B === null) {
            if (B === null && A !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (B !== null && A === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (A > B) {
                return -1;
              }
              if (B > A) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return B.localeCompare(A, 'zh-Hant');
            }
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
};
</script>
