/* eslint-disable no-labels */
<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style lang="scss">
@import 'assets/stylesheet/all.scss';
</style>
