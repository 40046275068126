<template>
  <div>
    <h1 class="title m-4">贈獎管理－紅利獎設定－兌獎名單</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input
              type="text"
              class="form-control mr-1"
              name="keywords"
              id="keywords"
              placeholder="輸入姓名、手機、得獎品項"
              v-model.trim="searchItem.keyword"
            />
            <button
              class="btn searching btn-main mx-3 align-self-end"
              type="button"
              @click="search"
            >
              <i class="fas fa-search mr-1 "></i>查詢
            </button>
          </div>
        </div>
      </form>
      <div class="add text-right d-flex justify-content-">
        <button class="btn-main btn" @click="download">
          <i class="fas fa-download"></i>數據下載
        </button>
      </div>
    </div>
    <div class="container">
      <div class="page">
        <div class="select-perpage d-flex justify-content-end align-items-center">
          <span>每頁顯示</span>
          <select class="form-select" v-model.number="perpage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <span>筆</span>
        </div>
      </div>
      <table class="table table-hover mt-4">
        <thead class="bg-thead">
          <tr>
            <th width="240" class="text-center" @click="changeType('name')">
              姓名
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'name'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="180" class="text-center" @click="changeType('phone')">
              手機
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'phone'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="280" class="text-center" @click="changeType('gift_item')">
              得獎品項
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'gift_item'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center" @click="changeType('notify_at')">
              得獎通知日期
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'notify_at'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center" @click="changeType('finish_at')">
              領獎表單完成日期
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'finish_at'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="180" class="text-center" @click="changeType('identity_card_status')">
              身分認證
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'identity_card_status'"
              >
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="180" class="text-center">寄送資訊</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in displayRecords()" :key="index">
            <td class="text-center">{{ item.name }}</td>
            <td class="text-center">{{ item.phone }}</td>
            <td class="text-center">{{ item.gift_item }}</td>
            <td class="text-center">{{ item.notify_at }}</td>
            <td class="text-center">{{ item.finish_at ? item.finish_at : '未完成' }}</td>
            <td class="text-center status_danger" v-if="item.identity_card_status != '認證成功'">
              <button
                class="customCheck"
                v-if="item.identity_card_status == '審核中'"
                @click="toCheckUser(item.phone)"
              >
                {{ item.identity_card_status }}
              </button>
              <button v-else>
                {{ item.identity_card_status }}
              </button>
            </td>
            <td class="text-center status_success" v-else>
              <button>認證成功</button>
            </td>
            <td class="text-center">
              <button class="btn btn-table btn-dark btn-sm" @click="openModal(item)">
                <i class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end">
        <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
        />
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="shippingInfoModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">寄送資訊</h5>
          <button type="button" class="close" @click="closeModal()">
            <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
          </button>
        </div>
        <div class="modal-body m-4">
          <Form @submit="onSubmit">
            <div class="mb-3 row">
              <p class="col-sm-3">寄送地址</p>
              <p class="col-sm-9">{{ shipDetails.address }}</p>
            </div>
            <div class="mb-3 row">
              <label for="shipping_at" class="col-sm-3 col-form-label">寄送日期</label>
              <div class="col-sm-7 calendar">
                <div class="dateSelect d-flex">
                  <Field
                    :rules="validate"
                    as="datepicker"
                    name="shipping_at"
                    id="shipping_at"
                    v-model="shipDetails.shipping_at"
                  />
                  <datepicker v-model="shipDetails.shipping_at" />
                  <span><i class="fas fa-calendar"></i></span>
                </div>
              </div>
              <div class="col-sm-2">
                <error-message name="shippingDate" class="error form-error"> </error-message>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="trackingCode" class="col-sm-3 col-form-label">寄送單號</label>
              <div class="col-sm-7">
                <Field
                  :rules="validate"
                  as="input"
                  type="text"
                  class="form-control"
                  name="trackingCode"
                  id="trackingCode"
                  v-model.trim="shipDetails.tracking_no"
                />
              </div>
              <div class="col-sm-2">
                <error-message name="trackingCode" class="error form-error"> </error-message>
              </div>
            </div>
            <button type="submit" class="btn btn-accent d-block m-auto">儲存</button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Datepicker from 'vue3-datepicker';
import Pagination from '../components/pagination.vue';
// eslint-disable-next-line camelcase
import { export_json_to_excel } from '../Excel/Export2Excel';

export default {
  components: {
    Datepicker,
    Pagination,
  },
  data() {
    return {
      staffId: '',
      status: '',
      searchItem: {
        activityID: '',
        groupID: '',
        keyword: '',
      },
      tableData: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      shipDetails: '',
      form: {
        bonusListId: '',
        shippingDate: '',
        trackingCode: '',
      },
      sortType: 'name',
      isReverse: false,
    };
  },
  methods: {
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) {
      // 日期格式轉換
      const date = new Date(str);
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      return [date.getFullYear(), mnth, day].join('/');
    },
    convertTime(str) {
      // 日期 & 時間格式轉換
      const date = new Date(str.replace(/\s/, 'T'));
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      const hours = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);
      const seconds = `0${date.getSeconds()}`.slice(-2);
      return `${[date.getFullYear(), mnth, day].join('/')} ${[hours, minutes, seconds].join(':')} `;
    },
    search() {
      // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardBonusExchangeList`;
      vm.$http
        .post(api, vm.searchItem)
        .then((res) => {
          // console.log(res);
          if (res.data.status === 200) {
            vm.tableData = res.data.data;
            for (let i = 0; i < vm.tableData.length; i += 1) {
              vm.tableData[i].notify_at = vm.convert(vm.tableData[i].notify_at); // 得獎通知日期格式轉換
              if (vm.tableData[i].finish_at) {
                // 完成表單時間格式轉換
                vm.tableData[i].finish_at = vm.convertTime(vm.tableData[i].finish_at);
              }
              if (vm.tableData[i].shipping_at) {
                // 寄送日期格式轉換
                vm.tableData[i].shipping_at = vm.convert(vm.tableData[i].shipping_at);
              }
            }
          } else {
            vm.tableData = [];
            Swal.fire({
              icon: 'warning',
              text: '查無相關資訊',
              confirmButtonColor: '#ec7805',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          vm.pageSize = vm.tableData.length;
          vm.searchItem.keyword = '';
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    toCheckUser(userPhone) {
      // 身份認證為'審核中'-> 前往身份審核表單
      const vm = this;
      const userphone = btoa(userPhone);
      vm.$router.push(`/admin/identityCheck/${vm.staffId}/${userphone}`);
    },
    // eslint-disable-next-line no-unused-vars
    openModal(details) {
      // 寄送資訊表單(modal)
      const vm = this;
      $('#shippingInfoModal').modal('show');
      vm.shipDetails = { ...details };
      if (vm.shipDetails.shipping_at !== '') {
        vm.shipDetails.shipping_at = new Date(vm.shipDetails.shipping_at);
      }
    },
    closeModal() {
      // 關閉寄送資訊表單(modal)
      $('#shippingInfoModal').modal('hide');
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) {
      // 送出寄送資訊表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardBonusExchangeProcess`;
      vm.form.bonusListId = vm.shipDetails.BonusList_id;
      vm.form.shippingDate = vm.convert(vm.shipDetails.shipping_at);
      vm.form.trackingCode = vm.shipDetails.tracking_no;
      vm.$http
        .post(api, vm.getFormData(vm.form))
        .then((res) => {
          if (res.data.status === 200) {
            $('#shippingInfoModal').modal('hide');
            vm.search();
          } else {
            $('#shippingInfoModal').modal('hide');
            Swal.fire({
              icon: 'error',
              html: '寄送資訊儲存失敗</br>請確認表單內容是否正確',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          vm.pageSize = vm.tableData.length;
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    validate(value) {
      // 表單驗證
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
    convertData(filter, ArrayData) {
      // 數據下載資料格式轉換
      return ArrayData.map((v) => filter.map((j) => String(v[j])));
    },
    download() {
      // 數據下載
      const vm = this;
      const data = vm.tableData;
      const date = vm
        .convert(new Date())
        .split('/')
        .join('');
      require.ensure([], () => {
        const tHeader = [
          '姓名',
          '手機',
          '得獎品項',
          '得獎通知日期',
          '領獎表單完成日期',
          '身份認證狀態',
          '寄送地址',
          '寄送日期',
          '寄送單號',
        ];
        const filter = [
          'name',
          'phone',
          'gift_item',
          'notify_at',
          'finish_at',
          'identity_card_status',
          'address',
          'shipping_at',
          'tracking_no',
        ];
        const convertedData = vm.convertData(filter, data);
        export_json_to_excel(tHeader, convertedData, `${date}_紅利獎兌換名單`);
        // export_json_to_excel(tHeader, convertedData, `${data.activityName}`);
      });
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) {
      // 分頁
    },
    displayRecords() {
      // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) {
      // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() {
      // 排序Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        const A = a[type];
        const B = b[type];
        // if (type === 'uploadNum' || type === 'matchNum') {
        //   A = a.memberDetails[type];
        //   B = b.memberDetails[type];
        // } else {
        //   A = a[type];
        //   B = b[type];
        // }
        if (vm.isReverse) {
          if (A === null || B === null) {
            if (A === null && B !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (A !== null && B === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (B > A) {
                return -1;
              }
              if (A > B) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return A.localeCompare(B, 'zh-Hant');
            }
          }
          // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (A === null || B === null) {
            if (B === null && A !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (B !== null && A === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (A > B) {
                return -1;
              }
              if (B > A) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return B.localeCompare(A, 'zh-Hant');
            }
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.searchItem.activityID = this.$route.params.activityID;
    this.searchItem.groupID = this.$route.params.bonusGroupID;
    // this.status = this.$route.params.status;
    this.search();
  },
};
</script>
