/* eslint-disable no-unused-vars */ /* eslint-disable camelcase */ /* eslint-disable import/named */
<template>
  <div>
    <h1 class="title m-4">數據儀表板</h1>
    <h2 class="subtitle m-4">活動數據報表</h2>
    <div class="container d-flex justify-content-between">
      <form class="search-bar m-2 d-flex align-items-center">
        <div>
          <h5>選擇報表:</h5>
          <div class="d-flex justify-content-end">
            <select
              class="table_select form-select"
              name="table_select"
              id="table_select"
              v-model="searchItemA.tableType"
            >
              <option value="報表一" selected>活動整體數據</option>
              <option value="報表二">發票全品項數據</option>
              <option value="報表三">仕高品項數據</option>
              <option value="報表四">會員資料數據</option>
            </select>
          </div>
        </div>
        <div class="mx-3">
          <h5>日期時間:</h5>
          <div class="calendar d-flex align-items-center">
            <div class="dateSelect d-flex">
              <datepicker
                v-model="searchItemA.startDate"
                inputFormat="yyyy/MM/dd"
                :upper-limit="searchItemA.endDate == '' ? false : searchItemA.endDate"
                placeholder="起始日"
              />
              <span><i class="fas fa-calendar"></i></span>
            </div>
            <span class="mx-1">~</span>
            <div class="dateSelect d-flex">
              <datepicker
                v-model="searchItemA.endDate"
                inputFormat="yyyy/MM/dd"
                :lower-limit="searchItemA.startDate == '' ? false : searchItemA.startDate"
                placeholder="結束日"
              />
              <span><i class="fas fa-calendar"></i></span>
            </div>
          </div>
        </div>
      </form>
      <div class="buttons d-flex m-2 align-self-end justify-content-end">
        <button class="btn searching btn-main mx-3 align-self-end" type="button" @click="searchA">
          <i class="fas fa-search mr-1 "></i>查詢
        </button>
        <button class="btn-accent btn align-self-end" @click="downloadA(tableData)">
          <i class="fas fa-download"></i>數據下載
        </button>
      </div>
    </div>
    <div class="container">
      <!-- 報表一 Start -->
      <table class="table table-hover mt-4" v-if="tableTypeA == '報表一'">
        <thead>
          <tr class="bg-thead">
            <th width="180" class="text-center" @click="changeType('activityName')">
              活動名稱
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'activityName'">
                <i class="fas fa-angle-up text-success mx-1"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('activityStart')">
              活動日期
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'activityStart'">
                <i class="fas fa-angle-up text-success mx-1"></i>
              </span>
            </th>
            <th width="150" class="text-center" @click="changeType('memberNum')">
              活動參與人數
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'memberNum'">
                <i class="fas fa-angle-up text-success mx-1"></i>
              </span>
            </th>
            <th width="150" class="text-center" @click="changeType('invoiceNum')">
              登錄發票數
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'invoiceNum'">
                <i class="fas fa-angle-up text-success mx-1"></i>
              </span>
            </th>
            <th width="150" class="text-center" @click="changeType('passInvoiceNum')">
              合格發票數
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'passInvoiceNum'"
              >
                <i class="fas fa-angle-up text-success mx-1"></i>
              </span>
            </th>
            <th width="180" class="text-center" @click="changeType('checkInvoiceNum')">
              待人工審核發票數
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'checkInvoiceNum'"
              >
                <i class="fas fa-angle-up text-success mx-1"></i>
              </span>
            </th>
            <th width="180" class="text-center" @click="changeType('govCheckNum')">
              待財政部審核發票數
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'govCheckNum'">
                <i class="fas fa-angle-up text-success mx-1"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in displayRecords()" :key="index">
            <td class="text-center">{{ item.activityName }}</td>
            <td class="text-center">{{ item.activityStart }} ~ {{ item.activityEnd }}</td>
            <td class="text-center">{{ toThousands(item.memberNum) }}</td>
            <td class="text-center">{{ toThousands(item.invoiceNum) }}</td>
            <td class="text-center">{{ toThousands(item.passInvoiceNum) }}</td>
            <td class="text-center">{{ toThousands(item.checkInvoiceNum) }}</td>
            <td class="text-center">{{ toThousands(item.govCheckNum) }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 報表一 End -->
      <!-- 報表二 Start -->
      <table class="table table-hover mt-4" v-if="tableTypeA == '報表二'">
        <thead>
          <tr class="bg-thead">
            <th min-width="300" class="text-center" @click="changeType('entryTime')">
              發票登錄時間
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'entryTime'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th min-width="500" class="text-center" @click="changeType('userPhone')">
              會員手機
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userPhone'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="300" class="text-center" @click="changeType('userName')">
              會員姓名
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userName'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="500" class="text-center" @click="changeType('registerTime')">
              會員註冊時間
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'registerTime'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="" class="text-center" @click="changeType('userAge')">
              會員年紀
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userAge'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="" class="text-center" @click="changeType('invoiceCode')">
              發票號碼
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'invoiceCode'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="" class="text-center" @click="changeType('invoiceDate')">
              發票日期
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'invoiceDate'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="" class="text-center" @click="changeType('invoiceType')">
              發票類型
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'invoiceType'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="" class="text-center" @click="changeType('productName')">
              發票品項
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'productName'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="" class="text-center" @click="changeType('productNum')">
              品項數量
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'productNum'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="" class="text-center" @click="changeType('price')">
              品項金額
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'price'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="" class="text-center" @click="changeType('store')">
              發票開立公司
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'store'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="" class="text-center" @click="changeType('storeAddress')">
              賣方地址
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'storeAddress'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in displayRecords()" :key="index">
            <td class="text-center">{{ item.entryTime }}</td>
            <td class="text-center">{{ item.userPhone }}</td>
            <td class="text-center">{{ item.userName }}</td>
            <td class="text-center">{{ item.registerTime }}</td>
            <td class="text-center">{{ item.userAge }}</td>
            <td class="text-center">{{ item.invoiceCode }}</td>
            <td class="text-center">{{ item.invoiceDate }}</td>
            <td class="text-center">{{ item.invoiceType }}</td>
            <td class="text-center">{{ item.productName }}</td>
            <td class="text-center">{{ toThousands(item.productNum) }}</td>
            <td class="text-center">{{ toThousands(item.price) }}</td>
            <td class="text-center">{{ item.store }}</td>
            <td class="text-center">{{ item.storeAddress }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 報表二 End  -->
      <!-- 報表三 Start -->
      <table class="table table-hover mt-4" v-if="tableTypeA == '報表三'">
        <thead>
          <tr class="bg-thead">
            <th min-width="300" class="text-center" @click="changeType('invoiceCreatedAt')">
              發票登錄時間
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'invoiceCreatedAt'"
              >
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th min-width="500" class="text-center" @click="changeType('userPhone')">
              會員手機
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userPhone'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="300" class="text-center" @click="changeType('userName')">
              會員姓名
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userName'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="500" class="text-center" @click="changeType('userCreateAt')">
              會員註冊時間
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userCreateAt'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('userAge')">
              會員年紀
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userAge'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('invoiceNo')">
              發票號碼
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'invoiceNo'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('invoiceDate')">
              發票日期
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'invoiceDate'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('invoiceType')">
              發票類型
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'invoiceType'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('scoItem')">
              仕高利達發票品項(數)
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'scoItem'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('scoItemNum')">
              仕高利達品項數量
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'scoItemNum'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('scoItemTotalPrice')">
              仕高利達總金額
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'scoItemTotalPrice'"
              >
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('invoiceTotalPrice')">
              發票總金額
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'invoiceTotalPrice'"
              >
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('sellerName')">
              發票開立公司
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'sellerName'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th class="text-center" @click="changeType('sellerAddress')">
              賣方地址
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'sellerAddress'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in displayRecords()" :key="index">
            <td class="text-center">{{ item.invoiceCreatedAt }}</td>
            <td class="text-center">{{ item.userPhone }}</td>
            <td class="text-center">{{ item.userName }}</td>
            <td class="text-center">{{ item.userCreateAt }}</td>
            <td class="text-center">{{ item.userAge }}</td>
            <td class="text-center">{{ item.invoiceNo }}</td>
            <td class="text-center">{{ item.invoiceDate }}</td>
            <td class="text-center">{{ item.invoiceType }}</td>
            <td class="text-center">{{ item.scoItem }}</td>
            <td class="text-center">{{ toThousands(item.scoItemNum) }}</td>
            <td class="text-center">{{ toThousands(item.scoItemTotalPrice) }}</td>
            <td class="text-center">{{ toThousands(item.invoiceTotalPrice) }}</td>
            <td class="text-center">{{ item.sellerName }}</td>
            <td class="text-center">{{ item.sellerAddress }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 報表三 End -->
      <!-- 報表四 Start -->
      <table class="table table-hover mt-4" v-if="tableTypeA == '報表四'">
        <thead>
          <tr class="bg-thead">
            <th min-width="500" class="text-center" @click="changeType('userCreateAt')">
              會員註冊時間
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userCreateAt'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th min-width="300" class="text-center" @click="changeType('phonePhone')">
              會員手機
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'phonePhone'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center" @click="changeType('userName')">
              會員姓名
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userName'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center" @click="changeType('userAge')">
              會員年紀
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userAge'">
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center" @click="changeType('invoiceTotalNum')">
              登錄發票總數
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'invoiceTotalNum'"
              >
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center" @click="changeType('invoiceCertTotalNum')">
              合格發票總數
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'invoiceTotalNum'"
              >
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center" @click="changeType('scoItemTotalNum')">
              仕高利達品項總數量
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'scoItemTotalNum'"
              >
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center" @click="changeType('scoItemTotalPrice')">
              仕高利達總金額
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'scoItemTotalPrice'"
              >
                <i class="fas fa-angle-up text-success"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in displayRecords()" :key="index">
            <td class="text-center">{{ item.userCreateAt }}</td>
            <td class="text-center">{{ item.phonePhone }}</td>
            <td class="text-center">{{ item.userName }}</td>
            <td class="text-center">{{ item.userAge }}</td>
            <td class="text-center">{{ toThousands(item.invoiceTotalNum) }}</td>
            <td class="text-center">{{ toThousands(item.invoiceCertTotalNum) }}</td>
            <td class="text-center">{{ toThousands(item.scoItemTotalNum) }}</td>
            <td class="text-center">{{ toThousands(item.scoItemTotalPrice) }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 報表四 End -->
      <div class="d-flex justify-content-end">
        <pagination :records="pageSize" :per-page="perpage" v-model="page" @paginate="callback" />
      </div>
    </div>
    <h2 class="subtitle m-4">活動統計報表</h2>
    <div class="container d-flex justify-content-between">
      <form class="search-bar m-2 d-flex align-items-center">
        <div>
          <h5>選擇報表:</h5>
          <div class="d-flex justify-content-end">
            <select
              class="table_select form-select"
              name="table_select"
              id="table_select"
              v-model="searchItemB.tableType"
            >
              <option value="1" selected>通路x產品</option>
              <option value="2">整體統計報表</option>
              <option value="3">會員結構x整體</option>
              <option value="4">會員結構x新會員</option>
              <option value="5">會員結構x舊會員</option>
            </select>
          </div>
        </div>
        <div class="mx-3" v-if="searchItemB.tableType === '1'">
          <h5>日期時間:</h5>
          <div class="calendar d-flex align-items-center">
            <div class="dateSelect d-flex">
              <datepicker
                v-model="searchItemB.startedAt"
                inputFormat="yyyy/MM/dd"
                :upper-limit="searchItemB.endedAt == '' ? false : searchItemB.endedAt"
                placeholder="起始日"
              />
              <span><i class="fas fa-calendar"></i></span>
            </div>
            <span class="mx-1">~</span>
            <div class="dateSelect d-flex">
              <datepicker
                v-model="searchItemB.endedAt"
                inputFormat="yyyy/MM/dd"
                :lower-limit="searchItemB.startedAt == '' ? false : searchItemB.startedAt"
                placeholder="結束日"
              />
              <span><i class="fas fa-calendar"></i></span>
            </div>
          </div>
        </div>
        <div class="mx-3" v-if="searchItemB.tableType !== '1'">
          <h5>活動名稱:</h5>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="searchItemB.activityID"
          >
            <option disabled value="">請選擇活動</option>
            <option v-for="(item, index) in actlist" :key="index" :value="item.activityID">{{
              item.activityName
            }}</option>
          </select>
        </div>
      </form>
      <div class="buttons d-flex m-2 align-self-end justify-content-end">
        <button class="btn searching btn-main mx-3 align-self-end" type="button" @click="searchB">
          <i class="fas fa-search mr-1 "></i>查詢
        </button>
        <button class="btn-accent btn align-self-end" @click="downloadB(statsData)">
          <i class="fas fa-download"></i>數據下載
        </button>
      </div>
    </div>
    <div class="container">
      <!-- 通路x產品 Start -->
      <table
        class="table table-hover table-bordered border border-dark mt-4"
        v-if="tableTypeB == '1'"
      >
        <thead>
          <tr class="stat bg-thead">
            <th scope="col" rowspan="3" class="bg-white border border-dark"></th>
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" colspan="2" class="text-center border border-bottom border-dark">
                {{ item }}
              </th></template
            >
          </tr>
          <tr class="bg-light">
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" class="text-center">產品總數量</th>
              <th scope="col" class="text-center">產品總金額</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in statsData"
            :key="index"
            :class="{ 'bg-secondary text-white': index === statsData.length - 1 }"
          >
            <th scope="row">{{ item.itemName }}</th>
            <template v-for="(item1, index1) in Object.keys(statsData[0])" :key="index1">
              <td class="text-center" v-if="typeof item[item1] === 'object'">
                {{ toThousands(item[item1].productTotalQnt) }}
              </td>
              <td class="text-center" v-if="typeof item[item1] === 'object'">
                {{ toThousands(item[item1].productTotalPrice) }}
              </td></template
            >
          </tr>
        </tbody>
      </table>
      <!-- 通路x產品 End -->
      <!-- 整體統計報表 Start -->
      <table
        class="table table-hover table-bordered border border-dark mt-4"
        v-if="tableTypeB == '2'"
      >
        <thead>
          <tr class="stat bg-thead">
            <th scope="col" rowspan="3" class="bg-white border border-dark"></th>
            <th scope="col" colspan="10" rowspan="2" class="text-center border border-dark">
              <span>活動</span>
            </th>
            <th scope="col" colspan="3" rowspan="2" class="text-center border border-dark">
              <span>所有合格發票</span>
            </th>
            <th scope="col" colspan="3" rowspan="2" class="text-center border border-dark">
              <span>符合抽獎資格發票</span>
            </th>
            <th scope="col" colspan="3" rowspan="2" class="text-center border border-dark">
              <span>符合滿額獎資格發票</span>
            </th>
            <th scope="col" colspan="6" rowspan="2" class="text-center border border-dark">
              <span>產品總數</span>
            </th>
            <th scope="col" :colspan="storeNum * 4" class="text-center border border-dark">
              <span>通路</span>
            </th>
          </tr>
          <tr class="bg-thead">
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" colspan="4" class="text-center border-bottom border-dark">
                {{ item }}
              </th></template
            >
          </tr>
          <tr class="bg-light">
            <th scope="col" class="text-center">參與人數</th>
            <th scope="col" class="text-center">平均年齡</th>
            <th scope="col" class="text-center">發票登錄總數</th>
            <th scope="col" class="text-center">電子發票張數</th>
            <th scope="col" class="text-center">雲端發票張數</th>
            <th scope="col" class="text-center">傳統發票張數</th>
            <th scope="col" class="text-center">仕高品項總金額</th>
            <th scope="col" class="text-center">仕高品項總數量</th>
            <th scope="col" class="text-center">平均品項單價</th>
            <th scope="col" class="text-center">單張發票平均品項金額</th>
            <!-- 所有合格發票 -->
            <th scope="col" class="text-center">總數量</th>
            <th scope="col" class="text-center">人數</th>
            <th scope="col" class="text-center">平均年齡</th>
            <!-- 符合抽獎資格發票 -->
            <th scope="col" class="text-center">總數量</th>
            <th scope="col" class="text-center">人數</th>
            <th scope="col" class="text-center">平均年齡</th>
            <!-- 符合滿額獎資格發票 -->
            <th scope="col" class="text-center">總數量</th>
            <th scope="col" class="text-center">人數</th>
            <th scope="col" class="text-center">平均年齡</th>
            <!-- 產品總數  -->
            <th scope="col" class="text-center">金牌</th>
            <th scope="col" class="text-center">12年</th>
            <th scope="col" class="text-center">金雪莉</th>
            <th scope="col" class="text-center">精選</th>
            <th scope="col" class="text-center">經典</th>
            <th scope="col" class="text-center">其他</th>
            <!-- 通路 Start -->
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" class="text-center">合格發票數</th>
              <th scope="col" class="text-center">產品總金額</th>
              <th scope="col" class="text-center">符合抽獎發票數</th>
              <th scope="col" class="text-center">符合滿額獎發票數</th>
            </template>
            <!-- 通路 End -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in statsData"
            :key="index"
            :class="{ 'bg-secondary text-white': index === statsData.length - 1 }"
          >
            <th scope="row">{{ item.mem }}</th>
            <td class="text-center">{{ toThousands(item.joinUserCount) }}</td>
            <td class="text-center">{{ item.averageAge }}</td>
            <td class="text-center">{{ toThousands(item.registerInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.electricInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.cloudInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.traditionalInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.scoItemTotalPrice) }}</td>
            <td class="text-center">{{ toThousands(item.scoItemTotalNum) }}</td>
            <td class="text-center">{{ item.averageItemPrice }}</td>
            <td class="text-center">{{ item.averageEveryInvoiceItemPrice }}</td>
            <td class="text-center">{{ toThousands(item.allCertInvoice.totalNum) }}</td>
            <td class="text-center">{{ toThousands(item.allCertInvoice.num) }}</td>
            <td class="text-center">{{ item.allCertInvoice.averageAge }}</td>
            <td class="text-center">{{ toThousands(item.allPickerInvoice.totalNum) }}</td>
            <td class="text-center">{{ toThousands(item.allPickerInvoice.num) }}</td>
            <td class="text-center">{{ item.allPickerInvoice.averageAge }}</td>
            <td class="text-center">{{ toThousands(item.allFillInvoice.totalNum) }}</td>
            <td class="text-center">{{ toThousands(item.allFillInvoice.num) }}</td>
            <td class="text-center">{{ item.allFillInvoice.averageAge }}</td>
            <td class="text-center" v-for="(item, index) in item.invoiceByProducts" :key="index">
              {{ toThousands(item) }}
            </td>
            <template v-for="(item, index) in item.invoiceByEcStores" :key="index">
              <td class="text-center">{{ toThousands(item.certInvoiceTotalCount) }}</td>
              <td class="text-center">{{ toThousands(item.productTotalPrice) }}</td>
              <td class="text-center">{{ toThousands(item.pickerInvoiceTotalCount) }}</td>
              <td class="text-center">
                {{ toThousands(item.fillInvoiceTotalCount) }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <!-- 整體統計報表 End -->

      <!-- 會員結構x整體 Start -->
      <table
        class="table table-hover table-bordered border border-dark mt-4"
        v-if="tableTypeB == 3"
      >
        <thead>
          <tr class="stat bg-thead">
            <th scope="col" rowspan="3" class="bg-white border border-dark"></th>
            <th scope="col" colspan="9" rowspan="2" class="text-center border border-dark">
              <span>活動</span>
            </th>
            <th scope="col" colspan="6" rowspan="2" class="text-center border border-dark">
              <span>產品總數</span>
            </th>
            <th scope="col" :colspan="storeNum * 4" class="text-center border border-dark">
              <span>通路</span>
            </th>
          </tr>
          <tr class="bg-thead">
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" colspan="4" class="text-center border-bottom border-dark">
                {{ item }}
              </th></template
            >
          </tr>
          <tr class="bg-light">
            <!-- 活動 start -->
            <th scope="col" class="text-center">新會員人數</th>
            <th scope="col" class="text-center">舊會員人數</th>
            <th scope="col" class="text-center">發票登錄總數</th>
            <th scope="col" class="text-center">發票合格總數</th>
            <th scope="col" class="text-center">發票合格不重複人數</th>
            <th scope="col" class="text-center">發票符合抽獎總數</th>
            <th scope="col" class="text-center">發票符合抽獎不重複人數</th>
            <th scope="col" class="text-center">發票符合滿額獎總數</th>
            <th scope="col" class="text-center">發票符合滿額獎不重複人數</th>
            <!-- 活動 End -->
            <!-- 產品總數 Start -->
            <th scope="col" class="text-center">金牌</th>
            <th scope="col" class="text-center">12年</th>
            <th scope="col" class="text-center">金雪莉</th>
            <th scope="col" class="text-center">精選</th>
            <th scope="col" class="text-center">經典</th>
            <th scope="col" class="text-center">其他</th>
            <!-- 產品總數 End -->
            <!-- 通路 Start -->
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" class="text-center">合格發票數</th>
              <th scope="col" class="text-center">產品總金額</th>
              <th scope="col" class="text-center">符合抽獎發票數</th>
              <th scope="col" class="text-center">符合滿額獎發票數</th>
            </template>
            <!-- 通路 End -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in statsData"
            :key="index"
            :class="{ 'bg-secondary text-white': index === statsData.length - 1 }"
          >
            <th scope="row">{{ item.age }}</th>
            <td class="text-center">{{ toThousands(item.newMemberTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.oldMemberTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.invoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.certInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.groupCertInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.pickerInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.groupPickerInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.fillInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.groupFillInvoiceTotalCount) }}</td>
            <td class="text-center" v-for="(item, index) in item.invoiceByProducts" :key="index">
              {{ toThousands(item) }}
            </td>
            <template v-for="(item, index) in item.invoiceByEcStores" :key="index">
              <td class="text-center">{{ toThousands(item.certInvoiceTotalCount) }}</td>
              <td class="text-center">{{ toThousands(item.productTotalPrice) }}</td>
              <td class="text-center">{{ toThousands(item.pickerInvoiceTotalCount) }}</td>
              <td class="text-center">
                {{ toThousands(item.fillInvoiceTotalCount) }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <!-- 會員結構x整體 End -->

      <!-- 會員結構x新會員 Start -->
      <table
        class="table table-hover table-bordered border border-dark mt-4"
        v-if="tableTypeB == 4"
      >
        <thead>
          <tr class="stat bg-thead">
            <th scope="col" rowspan="3" class="bg-white border border-dark"></th>
            <th scope="col" colspan="7" rowspan="2" class="text-center border border-dark">
              <span>活動</span>
            </th>
            <th scope="col" colspan="6" rowspan="2" class="text-center border border-dark">
              <span>產品總數</span>
            </th>
            <th scope="col" :colspan="storeNum * 4" class="text-center border border-dark">
              <span>通路</span>
            </th>
          </tr>
          <tr class="bg-thead">
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" colspan="4" class="text-center border-bottom border-dark">
                {{ item }}
              </th></template
            >
          </tr>
          <tr class="bg-light">
            <!-- 活動 -->
            <th scope="col" class="text-center">發票登錄總數</th>
            <th scope="col" class="text-center">發票合格總數</th>
            <th scope="col" class="text-center">發票合格不重複人數</th>
            <th scope="col" class="text-center">發票符合抽獎總數</th>
            <th scope="col" class="text-center">發票符合抽獎不重複人數</th>
            <th scope="col" class="text-center">發票符合滿額獎總數</th>
            <th scope="col" class="text-center">發票符合滿額獎不重複人數</th>
            <!-- 產品總數  -->
            <th scope="col" class="text-center">金牌</th>
            <th scope="col" class="text-center">12年</th>
            <th scope="col" class="text-center">金雪莉</th>
            <th scope="col" class="text-center">精選</th>
            <th scope="col" class="text-center">經典</th>
            <th scope="col" class="text-center">其他</th>
            <!-- 通路 Start -->
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" class="text-center">合格發票數</th>
              <th scope="col" class="text-center">產品總金額</th>
              <th scope="col" class="text-center">符合抽獎發票數</th>
              <th scope="col" class="text-center">符合滿額獎發票數</th>
            </template>
            <!-- 通路 End -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in statsData"
            :key="index"
            :class="{ 'bg-secondary text-white': index === statsData.length - 1 }"
          >
            <th scope="row">{{ item.age }}</th>
            <td class="text-center">{{ toThousands(item.invoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.certInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.groupCertInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.pickerInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.groupPickerInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.fillInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.groupFillInvoiceTotalCount) }}</td>
            <td class="text-center" v-for="(item, index) in item.invoiceByProducts" :key="index">
              {{ item }}
            </td>
            <template v-for="(item, index) in item.invoiceByEcStores" :key="index">
              <td class="text-center">{{ toThousands(item.certInvoiceTotalCount) }}</td>
              <td class="text-center">{{ toThousands(item.productTotalPrice) }}</td>
              <td class="text-center">{{ toThousands(item.pickerInvoiceTotalCount) }}</td>
              <td class="text-center">
                {{ toThousands(item.fillInvoiceTotalCount) }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <!-- 會員結構x新會員 End -->

      <!-- 會員結構x舊會員 Start -->
      <table
        class="table table-hover table-bordered border border-dark mt-4"
        v-if="tableTypeB == 5"
      >
        <thead>
          <tr class="stat bg-thead">
            <th scope="col" rowspan="3" class="bg-white border border-dark"></th>
            <th scope="col" colspan="7" rowspan="2" class="text-center border border-dark">
              <span>活動</span>
            </th>
            <th scope="col" colspan="6" rowspan="2" class="text-center border border-dark">
              <span>產品總數</span>
            </th>
            <th scope="col" :colspan="storeNum * 4" class="text-center border border-dark">
              <span>通路</span>
            </th>
          </tr>
          <tr class="bg-thead">
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" colspan="4" class="text-center border-bottom border-dark">
                {{ item }}
              </th></template
            >
          </tr>
          <tr class="bg-light">
            <!-- 活動 -->
            <th scope="col" class="text-center">發票登錄總數</th>
            <th scope="col" class="text-center">發票合格總數</th>
            <th scope="col" class="text-center">發票合格不重複人數</th>
            <th scope="col" class="text-center">發票符合抽獎總數</th>
            <th scope="col" class="text-center">發票符合抽獎不重複人數</th>
            <th scope="col" class="text-center">發票符合滿額獎總數</th>
            <th scope="col" class="text-center">發票符合滿額獎不重複人數</th>
            <!-- 產品總數  -->
            <th scope="col" class="text-center">金牌</th>
            <th scope="col" class="text-center">12年</th>
            <th scope="col" class="text-center">金雪莉</th>
            <th scope="col" class="text-center">精選</th>
            <th scope="col" class="text-center">經典</th>
            <th scope="col" class="text-center">其他</th>
            <!-- 通路 Start -->
            <template v-for="(item, index) in keys" :key="index">
              <th scope="col" class="text-center">合格發票數</th>
              <th scope="col" class="text-center">產品總金額</th>
              <th scope="col" class="text-center">符合抽獎發票數</th>
              <th scope="col" class="text-center">符合滿額獎發票數</th>
            </template>
            <!-- 通路 End -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in statsData"
            :key="index"
            :class="{ 'bg-secondary text-white': index === statsData.length - 1 }"
          >
            <th scope="row">{{ item.age }}</th>
            <td class="text-center">{{ toThousands(item.invoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.certInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.groupCertInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.pickerInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.groupPickerInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.fillInvoiceTotalCount) }}</td>
            <td class="text-center">{{ toThousands(item.groupFillInvoiceTotalCount) }}</td>
            <td class="text-center" v-for="(item, index) in item.invoiceByProducts" :key="index">
              {{ toThousands(item) }}
            </td>
            <template v-for="(item, index) in item.invoiceByEcStores" :key="index">
              <td class="text-center">{{ toThousands(item.certInvoiceTotalCount) }}</td>
              <td class="text-center">{{ toThousands(item.productTotalPrice) }}</td>
              <td class="text-center">{{ toThousands(item.pickerInvoiceTotalCount) }}</td>
              <td class="text-center">
                {{ toThousands(item.fillInvoiceTotalCount) }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <!-- 會員結構x舊會員 End -->
    </div>
    <div class="container mt-5" v-if="statsErrData">
      <p>待人工排除發票號碼：{{ statsErrData }}</p>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';
// import { ref } from 'vue';
import Swal from 'sweetalert2';
import Pagination from '../components/pagination.vue';
// eslint-disable-next-line camelcase
import { export_json_to_excel } from '../Excel/Export2Excel';
// eslint-disable-next-line camelcase
import { export_json_to_excel_1 } from '../Excel/Export2Excel_1'; // 通路x產品
// eslint-disable-next-line camelcase
import { export_json_to_excel_2 } from '../Excel/Export2Excel_2'; // 整體統計
// eslint-disable-next-line camelcase
import { export_json_to_excel_3 } from '../Excel/Export2Excel_3'; // 會員結構x整體
// eslint-disable-next-line camelcase
import { export_json_to_excel_4 } from '../Excel/Export2Excel_4'; // 會員結構(new)
// eslint-disable-next-line camelcase
import { export_json_to_excel_5 } from '../Excel/Export2Excel_5'; // 會員結構(old)

export default {
  components: {
    Datepicker,
    Pagination,
  },
  data() {
    return {
      searchItemA: {
        startDate: '',
        endDate: '',
        tableType: '報表一',
      },
      searchItemB: {
        startedAt: '',
        endedAt: '',
        tableType: '1',
        activityID: '',
      },
      tableTypeA: '報表一',
      tableTypeB: '1',
      tableData: '',
      statsData: '',
      statsErrData: '',
      storeNum: '',
      sortType: 'activityName',
      isReverse: true,
      pageSize: '',
      perpage: 10,
      page: 1,
      actlist: '',
      keys: '',
    };
  },
  methods: {
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) {
      // 日期格式轉換
      const date = new Date(str);
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) {
        return '';
        // eslint-disable-next-line no-else-return
      } else {
        return [date.getFullYear(), mnth, day].join('/');
      }
    },
    convertTime(str) {
      // 日期 & 時間格式轉換
      if (str) {
        const date = new Date(str.replace(/\s/, 'T'));
        const mnth = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        const hours = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);
        // const seconds = (`0${date.getSeconds()}`).slice(-2);
        return `${[date.getFullYear(), mnth, day].join('/')} ${[hours, minutes].join(':')} `;
      }
      return '';
    },
    toThousands(num) {
      // 千分位數加逗號
      let result = '';
      let counter = 0;
      // eslint-disable-next-line no-param-reassign
      num = (num || 0).toString();
      for (let i = num.length - 1; i >= 0; i -= 1) {
        counter += 1;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i !== 0) {
          result = `,${result}`;
        }
      }
      return result;
    },
    act() {
      // 導入活動選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actPrizeInfo`;
      vm.$http
        .post(api)
        .then((res) => {
          if (res.data.status === 200) {
            vm.actlist = res.data.activity;
          } else {
            Swal.fire({
              icon: 'warning',
              html: '查無活動選單',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          // eslint-disable-next-line no-unused-vars
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchA() {
      // 數據報表列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/data/dashbord`;
      vm.searchItemA.startDate = vm.convert(vm.searchItemA.startDate);
      vm.searchItemA.endDate = vm.convert(vm.searchItemA.endDate);
      vm.$http
        .post(api, vm.searchItemA)
        .then((res) => {
          vm.tableTypeA = vm.searchItemA.tableType;
          if (vm.searchItemA.tableType === '報表一') {
            // 活動整體數據
            if (res.data.status === '200') {
              vm.tableData = res.data.data;
              vm.sortType = 'activityName';
              for (let i = 0; i < vm.tableData.length; i += 1) {
                // 轉換時間格式
                vm.tableData[i].activityStart = vm.convertTime(vm.tableData[i].activityStart);
                vm.tableData[i].activityEnd = vm.convertTime(vm.tableData[i].activityEnd);
              }
            } else {
              vm.tableData = [];
            }
          } else if (vm.searchItemA.tableType === '報表二') {
            // 發票全品項數據
            if (res.data.status === '200') {
              vm.tableData = res.data.data;
              vm.sortType = 'entryTime';
              vm.isReverse = false;
              const finalData = [];
              let tempData = '';
              for (let i = 0; i < vm.tableData.length; i += 1) {
                vm.tableData[i].entryTime = vm.convertTime(vm.tableData[i].entryTime);
                vm.tableData[i].registerTime = vm.convertTime(vm.tableData[i].registerTime);
                vm.tableData[i].invoiceDate = vm.convertTime(vm.tableData[i].invoiceDate);
              }
              for (let i = 0; i < vm.tableData.length; i += 1) {
                // 取出所有品項名稱、品項數量、品項金額新增至array
                tempData = JSON.parse(JSON.stringify(vm.tableData[i]));
                for (let j = 0; j < vm.tableData[i].productName.length; j += 1) {
                  tempData.productName = vm.tableData[i].productName[j];
                  tempData.productNum = vm.tableData[i].productNum[j];
                  tempData.price = vm.tableData[i].price[j];
                  finalData.push(JSON.parse(JSON.stringify(tempData)));
                }
              }
              vm.tableData = JSON.parse(JSON.stringify(finalData));
            } else {
              vm.tableData = [];
            }
          } else if (vm.searchItemA.tableType === '報表三') {
            // 仕高品項數據
            vm.tableData = Object.values(res.data);
            vm.sortType = 'invoiceCreatedAt';
            vm.isReverse = false;
            for (let i = 0; i < vm.tableData.length; i += 1) {
              vm.tableData[i].invoiceCreatedAt = vm.convertTime(vm.tableData[i].invoiceCreatedAt);
              vm.tableData[i].userCreateAt = vm.convertTime(vm.tableData[i].userCreateAt);
              vm.tableData[i].invoiceDate = vm.convertTime(vm.tableData[i].invoiceDate);
            }
          } else {
            // 會員資料數據
            vm.tableData = Object.values(res.data);
            vm.sortType = 'userCreateAt';
            vm.isReverse = false;
            for (let i = 0; i < vm.tableData.length; i += 1) {
              vm.tableData[i].userCreateAt = vm.convertTime(vm.tableData[i].userCreateAt);
            }
          }
          vm.pageSize = vm.tableData.length;
          vm.searchItemA.startDate = '';
          vm.searchItemA.endDate = '';
          // eslint-disable-next-line no-unused-vars
        })
        .catch((error) => {
          console.log(error);
          // Swal.fire({
          //   icon: 'error',
          //   html: '暫時無法取得資訊<br>請稍候再試',
          //   confirmButtonColor: '#dc3545',
          //   confirmButtonText: '關閉',
          //   allowOutsideClick: false,
          // });
        });
    },
    // eslint-disable-next-line consistent-return
    searchB() {
      // 統計報表列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/data/static-report`;
      if (vm.searchItemB.tableType === '1') {
        vm.searchItemB.startedAt = vm.convert(vm.searchItemB.startedAt);
        vm.searchItemB.endedAt = vm.convert(vm.searchItemB.endedAt);
        vm.searchItemB.activityID = '';
      } else {
        vm.searchItemB.startedAt = '';
        vm.searchItemB.endedAt = '';
        if (vm.searchItemB.activityID === '') {
          Swal.fire({
            icon: 'error',
            html: '請選擇活動名稱',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
          return false;
        }
      }
      vm.$http
        .post(api, vm.searchItemB)
        .then((res) => {
          vm.statsData = res?.data;

          vm.tableTypeB = vm.searchItemB.tableType;
          if (vm.searchItemB.tableType === '1') {
            vm.keys = Object.keys(res.data.productItems[Object.keys(res.data.productItems)[0]]);
            vm.statsData = vm.statsData.productItems;
            vm.statsErrData = res?.data?.errorNum.join(',');
            vm.statsData['金牌'].itemName = '金牌';
            vm.statsData['12年'].itemName = '12年';
            vm.statsData['金雪莉'].itemName = '金雪莉';
            vm.statsData['精選'].itemName = '精選';
            vm.statsData['經典'].itemName = '經典';
            vm.statsData['其他'].itemName = '其他';
            vm.statsData = Object.values(vm.statsData);
            vm.statsData.Sum = vm.calcTotal(vm.statsData);
            vm.statsData.Sum.itemName = 'Sum';
            vm.statsData = Object.values(vm.statsData);
            vm.storeNum = Object.keys(vm.statsData[0]).length;
          }
          if (vm.searchItemB.tableType === '2') {
            vm.keys = Object.keys(res.data[Object.keys(res.data)[0]].invoiceByEcStores);
            vm.storeNum = vm.keys.length;
            vm.statsData.newMember.mem = '新會員';
            vm.statsData.oldMember.mem = '舊會員';
            vm.statsData = Object.values(res.data);
            vm.statsData.Sum = vm.calcTotal(vm.statsData);
            // 分別計算平均
            // 平均年齡
            vm.statsData.Sum.averageAge = (
              (vm.statsData[0].averageAge * vm.statsData[0].joinUserCount +
                vm.statsData[1].averageAge * vm.statsData[1].joinUserCount) /
              vm.statsData.Sum.joinUserCount
            ).toFixed(2);
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(vm.statsData.Sum.averageAge)) {
              vm.statsData.Sum.averageAge = 0;
            }
            // 平均品項單價
            vm.statsData.Sum.averageItemPrice = (
              vm.statsData.Sum.scoItemTotalPrice / vm.statsData.Sum.scoItemTotalNum
            ).toFixed(2);
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(vm.statsData.Sum.averageItemPrice)) {
              vm.statsData.Sum.averageItemPrice = 0;
            }
            // 單張發票平均品項金額＝仕高品項總金額/合格發票總數
            vm.statsData.Sum.averageEveryInvoiceItemPrice = (
              vm.statsData.Sum.scoItemTotalPrice / vm.statsData.Sum.allCertInvoice.totalNum
            ).toFixed(2);
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(vm.statsData.Sum.averageEveryInvoiceItemPrice)) {
              vm.statsData.Sum.averageEveryInvoiceItemPrice = 0;
            }
            // 所有合格發票平均年齡
            vm.statsData.Sum.allCertInvoice.averageAge = (
              (vm.statsData[0].allCertInvoice.averageAge * vm.statsData[0].allCertInvoice.num +
                vm.statsData[1].allCertInvoice.averageAge * vm.statsData[1].allCertInvoice.num) /
              vm.statsData.Sum.allCertInvoice.num
            ).toFixed(2);
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(vm.statsData.Sum.allCertInvoice.averageAge)) {
              vm.statsData.Sum.allCertInvoice.averageAge = 0;
            }
            // 符合抽獎資格發票平均年齡
            vm.statsData.Sum.allPickerInvoice.averageAge = (
              (vm.statsData[0].allPickerInvoice.averageAge * vm.statsData[0].allPickerInvoice.num +
                vm.statsData[1].allPickerInvoice.averageAge *
                  vm.statsData[1].allPickerInvoice.num) /
              vm.statsData.Sum.allPickerInvoice.num
            ).toFixed(2);
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(vm.statsData.Sum.allPickerInvoice.averageAge)) {
              vm.statsData.Sum.allPickerInvoice.averageAge = 0;
            }
            // 符合滿額獎資格發票平均年齡
            vm.statsData.Sum.allFillInvoice.averageAge = (
              (vm.statsData[0].allFillInvoice.averageAge * vm.statsData[0].allFillInvoice.num +
                vm.statsData[1].allFillInvoice.averageAge * vm.statsData[1].allFillInvoice.num) /
              vm.statsData.Sum.allFillInvoice.num
            ).toFixed(2);
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(vm.statsData.Sum.allFillInvoice.averageAge)) {
              vm.statsData.Sum.allFillInvoice.averageAge = 0;
            }
            vm.statsData.Sum.mem = 'Sum';
            vm.statsData = Object.values(vm.statsData);
          }
          if (vm.searchItemB.tableType !== '1' && vm.searchItemB.tableType !== '2') {
            vm.statsData.push(vm.calcTotal(vm.statsData));
            vm.keys = Object.keys(res.data[Object.keys(res.data)[0]].invoiceByEcStores);
            vm.storeNum = vm.keys.length;
            const ageList = ['18y-24y', '25y-34y', '35y-44y', '45y-54y', '55y以上', 'Sum'];
            for (let i = 0; i < ageList.length; i += 1) {
              vm.statsData[i].age = ageList[i];
            }
          }
          // eslint-disable-next-line no-unused-vars
        })
        .catch((error) => {
          console.log(error);
          // Swal.fire({
          //   icon: 'error',
          //   html: '暫時無法取得資訊<br>請稍候再試',
          //   confirmButtonColor: '#dc3545',
          //   confirmButtonText: '關閉',
          //   allowOutsideClick: false,
          // });
        });
    },
    convertData(filter, ArrayData) {
      // 數據下載資料格式轉換（JSON 格式化 -> JSON 轉換成 map)
      return ArrayData.map((v) => filter.map((j) => String(v[j])));
    },
    downloadA(data) {
      // 數據報表之數據下載
      const vm = this;
      const date = vm
        .convert(new Date())
        .split('/')
        .join('');
      // console.log(data);
      if (vm.tableTypeA === '報表一') {
        // 活動整體數據
        require.ensure([], () => {
          const tHeader = [
            '活動名稱',
            '活動起始日',
            '活動結束日',
            '註冊會員人數',
            '登錄發票數',
            '合格發票數',
            '待人工審核發票數',
            '待財政部審核發票數',
          ];
          const filter = [
            'activityName',
            'activityStart',
            'activityEnd',
            'memberNum',
            'invoiceNum',
            'passInvoiceNum',
            'checkInvoiceNum',
            'govCheckNum',
          ];
          const convertedData = vm.convertData(filter, data);
          export_json_to_excel(tHeader, convertedData, `活動整體數據_${date}`);
        });
      } else if (vm.tableTypeA === '報表二') {
        // 發票全品項數據
        require.ensure([], () => {
          const tHeader = [
            '發票登錄時間',
            '會員手機',
            '會員姓名',
            '會員註冊時間',
            '會員年紀',
            '發票號碼',
            '發票日期',
            '發票類型',
            '發票品項',
            '品項數量',
            '品項金額',
            '發票開立公司',
            '賣方地址',
          ];
          const filter = [
            'entryTime',
            'userPhone',
            'userName',
            'registerTime',
            'userAge',
            'invoiceCode',
            'invoiceDate',
            'invoiceType',
            'productName',
            'productNum',
            'price',
            'store',
            'storeAddress',
          ];
          const convertedData = vm.convertData(filter, data);
          export_json_to_excel(tHeader, convertedData, `發票全品項數據_${date}`);
        });
      } else if (vm.tableTypeA === '報表三') {
        // 仕高品項數據
        require.ensure([], () => {
          const tHeader = [
            '發票登錄時間',
            '會員手機',
            '會員姓名',
            '會員註冊時間',
            '會員年紀',
            '發票號碼',
            '發票日期',
            '發票類型',
            '仕高利達發票品項(數）',
            '仕高利達品項數量',
            '仕高利達總金額',
            '發票總金額',
            '發票開立公司',
            '賣方地址',
          ];
          const filter = [
            'invoiceCreatedAt',
            'userPhone',
            'userName',
            'userCreateAt',
            'userAge',
            'invoiceNo',
            'invoiceDate',
            'invoiceType',
            'scoItem',
            'scoItemNum',
            'scoItemTotalPrice',
            'invoiceTotalPrice',
            'sellerName',
            'sellerAddress',
          ];
          const convertedData = vm.convertData(filter, data);
          export_json_to_excel(tHeader, convertedData, `仕高品項數據_${date}`);
        });
      } else if (vm.tableTypeA === '報表四') {
        // 會員資料數據
        require.ensure([], () => {
          const tHeader = [
            '會員註冊時間',
            '會員手機',
            '會員姓名',
            '會員年紀',
            '登錄發票總數',
            '合格發票總數',
            '仕高利達品項總數量',
            '仕高利達總金額',
          ];
          const filter = [
            'userCreateAt',
            'phonePhone',
            'userName',
            'userAge',
            'invoiceTotalNum',
            'invoiceCertTotalNum',
            'scoItemTotalNum',
            'scoItemTotalPrice',
          ];
          const convertedData = vm.convertData(filter, data);
          export_json_to_excel(tHeader, convertedData, `會員資料數據_${date}`);
        });
      }
    },
    arraymove(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },
    downloadB(data) {
      // 統計報表之數據下載
      const vm = this;
      const date = vm
        .convert(new Date())
        .split('/')
        .join('');
      let actName;
      if (vm.searchItemB.activityID !== '') {
        for (let i = 0; i < vm.actlist.length; i += 1) {
          if (vm.actlist[i].activityID === vm.searchItemB.activityID) {
            actName = vm.actlist[i].activityName;
            break;
          }
        }
      }
      if (vm.tableTypeB === '1') {
        // 通路x產品
        let downloadData = '';
        const tempdata = [];
        const keys = Object.keys(data[0]);
        for (let i = 0; i < data.length; i += 1) {
          const temp = {};
          // eslint-disable-next-line dot-notation
          temp['itemName'] = vm.statsData[i].itemName;
          for (let j = 0; j < keys.length; j += 1) {
            temp[`Qnt_${keys[j]}`] = data[i][keys[j]].productTotalQnt;
            temp[`Price_${keys[j]}`] = data[i][keys[j]].productTotalPrice;
          }
          tempdata.push(temp);
        }
        downloadData = JSON.parse(JSON.stringify(tempdata));
        require.ensure([], () => {
          const tHeader = [[''], []];
          for (let i = 0; i < keys.length; i += 1) {
            if (keys[i] !== 'itemName') {
              tHeader[0].push('產品總數量');
              tHeader[0].push('產品總金額');
              tHeader[1].push('');
              tHeader[1].push(keys[i]);
            }
          }
          const filter = Object.keys(downloadData[0]);
          const convertedData = vm.convertData(filter, downloadData);
          export_json_to_excel_1(tHeader, convertedData, `通路x產品_${date}`);
        });
      } else if (vm.tableTypeB === '2') {
        // 整體統計
        const downloadData = [];
        let tempdata = '';
        const keys = Object.keys(data[0].invoiceByEcStores);
        for (let i = 0; i < data.length; i += 1) {
          tempdata = JSON.parse(JSON.stringify(vm.statsData[i]));
          // allCertInvoice 所有合格發票
          tempdata.allCertInvTotalNum = data[i].allCertInvoice.totalNum;
          tempdata.allCertInvNum = data[i].allCertInvoice.num;
          tempdata.allCertInvAvgAge = data[i].allCertInvoice.averageAge;
          // allFillInvoice 符合抽獎資格發票
          tempdata.allFillInvTotalNum = data[i].allFillInvoice.totalNum;
          tempdata.allFillInvNum = data[i].allFillInvoice.num;
          tempdata.allFillInvAvgAge = data[i].allFillInvoice.averageAge;
          // allPickerInvoice 符合滿額獎資格發票
          tempdata.allPickerInvTotalNum = data[i].allPickerInvoice.totalNum;
          tempdata.allPickerInvNum = data[i].allPickerInvoice.num;
          tempdata.allPickerInvAvgAge = data[i].allPickerInvoice.averageAge;
          // invoiceByProducts 產品總數
          tempdata.item_gold = data[i].invoiceByProducts['金牌'];
          tempdata.item_twelve = data[i].invoiceByProducts['12年'];
          tempdata.item_shelly = data[i].invoiceByProducts['金雪莉'];
          tempdata.item_special = data[i].invoiceByProducts['精選'];
          tempdata.item_classic = data[i].invoiceByProducts['經典'];
          tempdata.item_other = data[i].invoiceByProducts['其他'];
          // invoiceByEcStores 通路
          for (let j = 0; j < keys.length; j += 1) {
            tempdata[`certInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].certInvoiceTotalCount;
            tempdata[`Price_${keys[j]}`] = data[i].invoiceByEcStores[keys[j]].productTotalPrice;
            tempdata[`pickerInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].pickerInvoiceTotalCount;
            tempdata[`fillInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].fillInvoiceTotalCount;
          }
          downloadData.push(JSON.parse(JSON.stringify(tempdata)));
          for (let j = 0; j < keys.length; j += 1) {
            delete tempdata[`certInv_${keys[j]}`];
            delete tempdata[`Price_${keys[j]}`];
            delete tempdata[`pickerInv_${keys[j]}`];
            delete tempdata[`fillInv_${keys[j]}`];
          }
        }
        require.ensure([], () => {
          const tHeader = [
            [
              '',
              '參與人數',
              '平均年齡',
              '發票登錄總數',
              '電子發票張數',
              '雲端發票張數',
              '傳統發票張數',
              '仕高品項總金額',
              '仕高品項總數量',
              '平均品項單價',
              '單張發票平均品項金額',
              '總數量',
              '人數',
              '平均年齡',
              '總數量',
              '人數',
              '平均年齡',
              '總數量',
              '人數',
              '平均年齡',
              '金牌',
              '12年',
              '金雪莉',
              '精選',
              '經典',
              '其他',
            ],
            [
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
            ],
          ];
          for (let i = 0; i < keys.length; i += 1) {
            tHeader[0].push('合格發票數');
            tHeader[0].push('產品總金額');
            tHeader[0].push('符合抽獎發票數');
            tHeader[0].push('符合滿額獎發票數');
            tHeader[1].push(keys[i]);
            if (i !== keys.length - 1) {
              tHeader[1].push('');
              tHeader[1].push('');
              tHeader[1].push('');
            }
          }
          const filter = Object.keys(downloadData[0]);
          filter.splice(filter.indexOf('allCertInvoice'), 1);
          filter.splice(filter.indexOf('allFillInvoice'), 1);
          filter.splice(filter.indexOf('allPickerInvoice'), 1);
          filter.splice(filter.indexOf('invoiceByEcStores'), 1);
          filter.splice(filter.indexOf('invoiceByProducts'), 1);
          vm.arraymove(filter, filter.indexOf('mem'), 0);
          const convertedData = vm.convertData(filter, downloadData);
          export_json_to_excel_2(tHeader, convertedData, `整體統計_${actName}_${date}`);
        });
      } else if (vm.tableTypeB === '3') {
        // 會員結構ｘ整體
        const downloadData = [];
        let tempdata = '';
        const keys = Object.keys(data[0].invoiceByEcStores);
        for (let i = 0; i < data.length; i += 1) {
          tempdata = JSON.parse(JSON.stringify(vm.statsData[i]));
          // invoiceByProducts 產品總數
          tempdata.item_gold = data[i].invoiceByProducts['金牌'];
          tempdata.item_twelve = data[i].invoiceByProducts['12年'];
          tempdata.item_shelly = data[i].invoiceByProducts['金雪莉'];
          tempdata.item_special = data[i].invoiceByProducts['精選'];
          tempdata.item_classic = data[i].invoiceByProducts['經典'];
          tempdata.item_other = data[i].invoiceByProducts['其他'];
          // invoiceByEcStores 通路
          for (let j = 0; j < keys.length; j += 1) {
            tempdata[`certInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].certInvoiceTotalCount;
            tempdata[`Price_${keys[j]}`] = data[i].invoiceByEcStores[keys[j]].productTotalPrice;
            tempdata[`pickerInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].pickerInvoiceTotalCount;
            tempdata[`fillInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].fillInvoiceTotalCount;
          }
          downloadData.push(JSON.parse(JSON.stringify(tempdata)));
          for (let j = 0; j < keys.length; j += 1) {
            delete tempdata[`certInv_${keys[j]}`];
            delete tempdata[`Price_${keys[j]}`];
            delete tempdata[`pickerInv_${keys[j]}`];
            delete tempdata[`fillInv_${keys[j]}`];
          }
        }
        require.ensure([], () => {
          const tHeader = [
            [
              '',
              '新會員人數',
              '舊會員人數',
              '發票登錄總數',
              '發票合格總數',
              '發票合格不重複人數',
              '發票符合抽獎總數',
              '發票符合抽獎不重複人數',
              '發票符合滿額獎總數',
              '發票符合滿額獎不重複人數',
              '金牌',
              '12年',
              '金雪莉',
              '精選',
              '經典',
              '其他',
            ],
            ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          ];
          for (let i = 0; i < keys.length; i += 1) {
            tHeader[0].push('合格發票數');
            tHeader[0].push('產品總金額');
            tHeader[0].push('符合抽獎發票數');
            tHeader[0].push('符合滿額獎發票數');
            tHeader[1].push(keys[i]);
            if (i !== keys.length - 1) {
              tHeader[1].push('');
              tHeader[1].push('');
              tHeader[1].push('');
            }
          }
          const filter = Object.keys(downloadData[0]);
          filter.splice(filter.indexOf('invoiceByEcStores'), 1);
          filter.splice(filter.indexOf('invoiceByProducts'), 1);
          vm.arraymove(filter, filter.indexOf('age'), 0);
          const convertedData = vm.convertData(filter, downloadData);
          export_json_to_excel_3(tHeader, convertedData, `會員結構x整體_${actName}_${date}`);
        });
      } else if (vm.tableTypeB === '4') {
        // 會員結構ｘ新會員
        const downloadData = [];
        let tempdata = '';
        const keys = Object.keys(data[0].invoiceByEcStores);
        for (let i = 0; i < data.length; i += 1) {
          tempdata = JSON.parse(JSON.stringify(vm.statsData[i]));
          // invoiceByProducts 產品總數
          tempdata.item_gold = data[i].invoiceByProducts['金牌'];
          tempdata.item_twelve = data[i].invoiceByProducts['12年'];
          tempdata.item_shelly = data[i].invoiceByProducts['金雪莉'];
          tempdata.item_special = data[i].invoiceByProducts['精選'];
          tempdata.item_classic = data[i].invoiceByProducts['經典'];
          tempdata.item_other = data[i].invoiceByProducts['其他'];
          // invoiceByEcStores 通路
          for (let j = 0; j < keys.length; j += 1) {
            tempdata[`certInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].certInvoiceTotalCount;
            tempdata[`Price_${keys[j]}`] = data[i].invoiceByEcStores[keys[j]].productTotalPrice;
            tempdata[`pickerInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].pickerInvoiceTotalCount;
            tempdata[`fillInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].fillInvoiceTotalCount;
          }
          downloadData.push(JSON.parse(JSON.stringify(tempdata)));
          for (let j = 0; j < keys.length; j += 1) {
            delete tempdata[`certInv_${keys[j]}`];
            delete tempdata[`Price_${keys[j]}`];
            delete tempdata[`pickerInv_${keys[j]}`];
            delete tempdata[`fillInv_${keys[j]}`];
          }
        }
        require.ensure([], () => {
          const tHeader = [
            [
              '',
              '發票登錄總數',
              '發票合格總數',
              '發票合格不重複人數',
              '發票符合抽獎總數',
              '發票符合抽獎不重複人數',
              '發票符合滿額獎總數',
              '發票符合滿額獎不重複人數',
              '金牌',
              '12年',
              '金雪莉',
              '精選',
              '經典',
              '其他',
            ],
            ['', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          ];
          for (let i = 0; i < keys.length; i += 1) {
            tHeader[0].push('合格發票數');
            tHeader[0].push('產品總金額');
            tHeader[0].push('符合抽獎發票數');
            tHeader[0].push('符合滿額獎發票數');
            tHeader[1].push(keys[i]);
            if (i !== keys.length - 1) {
              tHeader[1].push('');
              tHeader[1].push('');
              tHeader[1].push('');
            }
          }
          const filter = Object.keys(downloadData[0]);
          filter.splice(filter.indexOf('invoiceByEcStores'), 1);
          filter.splice(filter.indexOf('invoiceByProducts'), 1);
          vm.arraymove(filter, filter.indexOf('age'), 0);
          const convertedData = vm.convertData(filter, downloadData);
          export_json_to_excel_4(tHeader, convertedData, `會員結構x新會員_${actName}_${date}`);
        });
      } else if (vm.tableTypeB === '5') {
        // 會員結構ｘ舊會員
        const downloadData = [];
        let tempdata = '';
        const keys = Object.keys(data[0].invoiceByEcStores);
        for (let i = 0; i < data.length; i += 1) {
          tempdata = JSON.parse(JSON.stringify(vm.statsData[i]));
          // invoiceByProducts 產品總數
          tempdata.item_gold = data[i].invoiceByProducts['金牌'];
          tempdata.item_twelve = data[i].invoiceByProducts['12年'];
          tempdata.item_shelly = data[i].invoiceByProducts['金雪莉'];
          tempdata.item_special = data[i].invoiceByProducts['精選'];
          tempdata.item_classic = data[i].invoiceByProducts['經典'];
          tempdata.item_other = data[i].invoiceByProducts['其他'];
          // invoiceByEcStores 通路
          for (let j = 0; j < keys.length; j += 1) {
            tempdata[`certInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].certInvoiceTotalCount;
            tempdata[`Price_${keys[j]}`] = data[i].invoiceByEcStores[keys[j]].productTotalPrice;
            tempdata[`pickerInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].pickerInvoiceTotalCount;
            tempdata[`fillInv_${keys[j]}`] =
              data[i].invoiceByEcStores[keys[j]].fillInvoiceTotalCount;
          }
          downloadData.push(JSON.parse(JSON.stringify(tempdata)));
          for (let j = 0; j < keys.length; j += 1) {
            delete tempdata[`certInv_${keys[j]}`];
            delete tempdata[`Price_${keys[j]}`];
            delete tempdata[`pickerInv_${keys[j]}`];
            delete tempdata[`fillInv_${keys[j]}`];
          }
        }
        require.ensure([], () => {
          const tHeader = [
            [
              '',
              '發票登錄總數',
              '發票合格總數',
              '發票合格不重複人數',
              '發票符合抽獎總數',
              '發票符合抽獎不重複人數',
              '發票符合滿額獎總數',
              '發票符合滿額獎不重複人數',
              '金牌',
              '12年',
              '金雪莉',
              '精選',
              '經典',
              '其他',
            ],
            ['', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          ];
          for (let i = 0; i < keys.length; i += 1) {
            tHeader[0].push('合格發票數');
            tHeader[0].push('產品總金額');
            tHeader[0].push('符合抽獎發票數');
            tHeader[0].push('符合滿額獎發票數');
            tHeader[1].push(keys[i]);
            if (i !== keys.length - 1) {
              tHeader[1].push('');
              tHeader[1].push('');
              tHeader[1].push('');
            }
          }
          const filter = Object.keys(downloadData[0]);
          filter.splice(filter.indexOf('invoiceByEcStores'), 1);
          filter.splice(filter.indexOf('invoiceByProducts'), 1);
          vm.arraymove(filter, filter.indexOf('age'), 0);
          const convertedData = vm.convertData(filter, downloadData);
          export_json_to_excel_5(tHeader, convertedData, `會員結構x舊會員_${actName}_${date}`);
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) {
      console.log(page);
    },
    displayRecords() {
      // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) {
      // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
    calcTotal(data) {
      // 計算 Sum Data
      const total = {};
      for (let i = 0; i < data.length; i += 1) {
        const keys = Object.keys(data[i]);
        for (let j = 0; j < keys.length; j += 1) {
          if (typeof data[i][keys[j]] !== 'string' && typeof data[i][keys[j]] !== 'object') {
            if (i === 0) {
              total[keys[j]] = data[i][keys[j]];
            } else {
              total[keys[j]] += data[i][keys[j]];
            }
          } else if (typeof data[i][keys[j]] !== 'string' && typeof data[i][keys[j]] === 'object') {
            const keys2 = Object.keys(data[i][keys[j]]);
            // eslint-disable-next-line no-unused-vars
            let keys3 = '';
            if (i === 0) {
              total[keys[j]] = {};
              for (let k = 0; k < keys2.length; k += 1) {
                if (typeof data[i][keys[j]][keys2[k]] !== 'object') {
                  total[keys[j]][keys2[k]] = data[i][keys[j]][keys2[k]];
                } else {
                  total[keys[j]][keys2[k]] = {};
                  keys3 = Object.keys(data[i][keys[j]][keys2[k]]);
                  for (let l = 0; l < keys3.length; l += 1) {
                    total[keys[j]][keys2[k]][keys3[l]] = data[i][keys[j]][keys2[k]][keys3[l]];
                  }
                }
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              for (let k = 0; k < keys2.length; k += 1) {
                if (typeof data[i][keys[j]][keys2[k]] !== 'object') {
                  total[keys[j]][keys2[k]] += data[i][keys[j]][keys2[k]];
                } else {
                  keys3 = Object.keys(data[i][keys[j]][keys2[k]]);
                  for (let l = 0; l < keys3.length; l += 1) {
                    total[keys[j]][keys2[k]][keys3[l]] += data[i][keys[j]][keys2[k]][keys3[l]];
                  }
                }
              }
            }
          }
        }
      }
      return total;
    },
  },
  computed: {
    sortData() {
      // 排序 Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        const A = a[type];
        const B = b[type];
        if (vm.isReverse) {
          if (A === null || B === null) {
            if (A === null && B !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (A !== null && B === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (B > A) {
                return -1;
              }
              if (A > B) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return A.localeCompare(B, 'zh-Hant');
            }
          }
          // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (A === null || B === null) {
            if (B === null && A !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (B !== null && A === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (A > B) {
                return -1;
              }
              if (B > A) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return B.localeCompare(A, 'zh-Hant');
            }
          }
        }
      });
    },
  },
  created() {
    this.act();
    this.searchA();
    this.searchB();
  },
};
</script>
