<template>
  <div>
    <h1 class="title m-4">贈獎管理－獎品管理</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input
              type="text"
              class="form-control mr-1"
              name="keyword"
              id="keyword"
              placeholder="輸入獎品名稱"
              v-model.trim="searchItem.keyword"
            />
          </div>
        </div>
        <div>
          <h5>獎品類型:</h5>
          <div class="d-flex">
            <select
              class="select-status form-select"
              name="gifts_category"
              id="gifts_category"
              v-model="searchItem.giftType"
            >
              <option value="" selected>所有類型</option>
              <option value="實體獎">實體獎</option>
              <option value="虛擬獎">虛擬獎</option>
            </select>
            <button class="btn searching btn-main" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢
            </button>
          </div>
        </div>
      </form>
      <div class="add text-right d-flex">
        <button class="btn btn-main" @click="updateGift(true, '')">＋新增獎品</button>
      </div>
    </div>
    <div class="container">
      <div class="page">
        <div class="select-perpage d-flex justify-content-end align-items-center">
          <span>每頁顯示</span>
          <select class="form-select" v-model.number="perpage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <span>筆</span>
        </div>
      </div>
      <table class="table table-hover mt-4">
        <thead class="bg-thead">
          <tr>
            <th width="480" class="text-center" @click="changeType('giftName')">
              獎品名稱
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'giftName'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="480" class="text-center" @click="changeType('giftType')">
              獎品類型
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'giftType'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="300" class="text-center">狀態</th>
            <th width="200" class="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in displayRecords()" :key="index">
            <td class="text-center">{{ item.giftName }}</td>
            <td class="text-center">{{ item.giftType }}</td>
            <td class="text-center align-middle">
              <div class="form-check form-switch d-flex justify-content-center">
                <input
                  class="text-center form-check-input"
                  type="checkbox"
                  v-model="item.giftStatus"
                  @change="switchStatus(item.giftId, item.giftStatus)"
                />
              </div>
            </td>
            <td class="align-middle">
              <button class="btn btn-table btn-success btn-sm" @click="updateGift(false, item)">
                <i class="fas fa-pen"></i>
              </button>
              <button class="btn btn-table btn-danger btn-sm" @click="delModal(item)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
          <!-- <tr>
                    <td class="text-center">Nintendo Switch 健身組</td>
                    <td class="text-center">實體獎品</td>
                    <td class="text-center">
                      <div class="form-check form-switch d-flex justify-content-center">
                        <input class="text-center form-check-input"
                        type="checkbox" checked>
                      </div>
                    </td>
                    <td class="text-center">
                       <button class="btn btn-table btn-success btn-sm"
                       @click="updateGift(false,item)">
                          <i class="fas fa-pen"></i></button>
                       <button class="btn btn-table btn-danger btn-sm" @click="delModal()">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr> -->
        </tbody>
      </table>
      <div class="d-flex justify-content-end">
        <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
        />
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="giftModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">新增獎品</h5>
          <button type="button" class="close" @click="closeModal()">
            <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
          </button>
        </div>
        <div class="modal-body m-4">
          <Form @submit="onSubmit" v-slot="{}">
            <div class="mb-5 row">
              <label for="giftName" class="col-sm-3 col-form-label">獎品名稱</label>
              <div class="col-sm-9">
                <Field
                  :rules="validate"
                  as="input"
                  class="form-control"
                  name="giftName"
                  id="giftName"
                  v-model="giftDetails.giftName"
                  maxlength="50"
                  @input="wordsCount(giftDetails.giftName)"
                />
              </div>
              <div class="col-sm-2">
                <error-message name="giftName" class="error form-error"> </error-message>
              </div>
              <div class="col-sm-10">
                <span class="m-1 float-end text-gray">{{ words }}/50</span>
              </div>
            </div>
            <div class="mb-5 row">
              <label for="giftType" class="col-sm-3 col-form-label">獎品類型</label>
              <div class="col-sm-9">
                <Field
                  :rules="validate"
                  as="select"
                  class="form-select"
                  aria-label="select_category"
                  name="giftType"
                  id="giftType"
                  v-model="giftDetails.giftType"
                >
                  <option disabled value="">請選擇獎品類型</option>
                  <option value="實體獎">實體獎</option>
                  <option value="虛擬獎">虛擬獎</option>
                </Field>
              </div>
              <div class="col-sm-2">
                <error-message name="giftType" class="error form-error"> </error-message>
              </div>
            </div>
            <div class="mb-5 row">
              <label for="giftName" class="col-sm-3 col-form-label">獎品圖片</label>
              <div class="col-sm-9">
                <Field
                  :rules="{ size: 2048 }"
                  as="input"
                  type="file"
                  class="form-control"
                  placeholder="上傳獎品圖片"
                  accept=".jpeg,.png,.jpg,.gif,.svg"
                  @change="upload($event)"
                  name="giftImage"
                  ref="giftImage"
                >
                  <!-- <input
                    type="file"
                    class="form-control"
                    placeholder="上傳獎品圖片"
                    accept=".jpeg,.png,.jpg,.gif,.svg"
                    @change="upload($event)"
                    :value="uploadImage"

                  /> -->
                </Field>
                <div class="image-frame position-relative" v-if="giftDetails.giftImage">
                  <img :src="giftDetails.giftImage" alt="" />
                  <!-- <button
                    type="button"
                    class="close position-absolute"
                    @click="delImage"
                    style="right: 10px;top: 10px"
                  >
                    <span aria-hidden="true"><i class="fas fa-times-circle text-dark"></i></span>
                  </button> -->
                </div>
              </div>

              <div class="col-sm-12 mt-2">
                <error-message name="giftImage" class="error form-error"> </error-message>
              </div>
              <div class="col-sm-12 mt-2">
                <p class="error">＊目前僅有紅利獎品需上傳圖片</p>
              </div>
            </div>
            <div class="mb-5 row">
              <label for="inputStatus" class="col-sm-3">狀態</label>
              <div class="col-sm-9">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="T"
                    v-model="giftDetails.giftStatus"
                    checked
                  />
                  <label class="form-check-label" for="inlineRadio1">啟用</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="F"
                    v-model="giftDetails.giftStatus"
                  />
                  <label class="form-check-label" for="inlineRadio2">關閉</label>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-accent d-block m-auto">
              儲存
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="delGiftModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <span>刪除獎品</span>
          </h5>
        </div>
        <div class="modal-body">
          是否刪除
          <strong class="text-danger"> {{ giftDetails.giftName }}</strong> (刪除後將無法恢復)。
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" @click="cancelModal()">
            取消
          </button>
          <button class="btn btn-danger btn-sm py-2" @click="delConfirm(giftDetails)">
            確認刪除
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';

export default {
  components: {},
  data() {
    return {
      tempSearchItem: {
        keyword: '',
        giftType: '',
      },
      searchItem: {
        keyword: '',
        giftType: '',
      },
      giftDetails: '',
      uploadImage: '',
      tableData: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      staffId: '',
      sortType: 'giftName',
      isReverse: true,
      words: 0,
    };
  },
  methods: {
    wordsCount(input) {
      // 字數限制
      const vm = this;
      const wordsLength = input.length;
      vm.words = wordsLength;
    },
    upload(e) {
      const vm = this;
      const file = e.target.files[0];
      vm.uploadImage = e.target.value;
      if (file) {
        vm.giftDetails.giftImage = vm.imageToBase64(file);
        vm.giftDetails.file_extension = e.target.value.split('.').pop();
      } else {
        vm.giftDetails.giftImage = '';
        vm.giftDetails.file_extension = '';
      }
    },
    delImage() {
      this.giftDetails.giftImage = '';
      this.giftDetails.file_extension = '';
      this.uploadImage = null;
    },
    imageToBase64(file) {
      const vm = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        vm.giftDetails.giftImage = reader.result;
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    },
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) {
      // 日期格式轉換
      const date = new Date(str);
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      return [date.getFullYear(), mnth, day].join('/');
    },
    search() {
      // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/gift`;
      // eslint-disable-next-line prefer-object-spread
      vm.tempSearchItem = Object.assign({}, vm.searchItem);
      vm.$http
        .post(api, vm.tempSearchItem)
        .then((res) => {
          if (res.data.status === '200') {
            vm.tableData = res.data.data;
            if (vm.tableData.length !== 0) {
              for (let i = 0; i < vm.tableData.length; i += 1) {
                // 開啟關閉狀態value轉換
                if (vm.tableData[i].giftStatus === 'T') {
                  vm.tableData[i].giftStatus = true;
                } else {
                  vm.tableData[i].giftStatus = false;
                }
              }
            } else {
              Swal.fire({
                icon: 'warning',
                text: '查無相關資訊',
                confirmButtonColor: '#ec7805',
                confirmButtonText: '關閉',
                allowOutsideClick: false,
              });
            }
          } else {
            vm.tableData = [];
            Swal.fire({
              icon: 'warning',
              text: '查無相關資訊',
              confirmButtonColor: '#ec7805',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          vm.pageSize = vm.tableData.length;
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            icon: 'error',
            html: '暫時無法取得資訊<br>請稍候再試',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        });
    },
    switchStatus(giftId, giftStatus) {
      // switch開關狀態
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/gift/updateStatus`;
      if (giftStatus === true) {
        // 開啟關閉狀態value轉換為"T" "F"
        // eslint-disable-next-line no-param-reassign
        giftStatus = 'T';
      } else {
        // eslint-disable-next-line no-param-reassign
        giftStatus = 'F';
      }
      const postData = { giftId, giftStatus };
      vm.$http.post(api, postData).then((res) => {
        if (res.data.status === 200) {
          vm.search();
        }
      });
    },
    updateGift(New, details) {
      // 新增或編輯獎品
      const vm = this;
      $('#giftModal').modal('show');
      if (New) {
        // eslint-disable-next-line no-param-reassign
        details = {
          giftStatus: 'T',
          giftName: '',
          giftType: '',
          giftImage: '',
          file_extension: '',
        };
        vm.giftDetails = { ...details };
        vm.New = true;
      } else {
        vm.giftDetails = { ...details };
        if (vm.giftDetails.giftStatus === true) {
          vm.giftDetails.giftStatus = 'T';
        } else {
          vm.giftDetails.giftStatus = 'F';
        }
        vm.New = false;
      }
      vm.wordsCount(vm.giftDetails.giftName);
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) {
      // 送出表單
      console.log(values);
      const vm = this;
      const apiNew = `${process.env.VUE_APP_URL}api/award/gift/create`;
      const apiEdit = `${process.env.VUE_APP_URL}api/award/gift/update`;
      let api;
      const GiftId = vm.giftDetails.giftId;
      let postData;
      if (typeof GiftId === 'undefined') {
        api = apiNew;
        postData = {
          giftName: vm.giftDetails.giftName,
          giftType: vm.giftDetails.giftType,
          giftStatus: vm.giftDetails.giftStatus,
          image: vm.giftDetails.giftImage,
          file_extension: vm.giftDetails.file_extension,
        };
      } else {
        api = apiEdit;
        postData = {
          giftId: vm.giftDetails.giftId,
          giftName: vm.giftDetails.giftName,
          giftType: vm.giftDetails.giftType,
          giftStatus: vm.giftDetails.giftStatus,
          image: vm.giftDetails.giftImage,
          file_extension: vm.giftDetails.file_extension,
        };
      }
      this.uploadImage = '';
      this.$refs.giftImage.$el.value = '';
      vm.$http
        .post(api, postData)
        .then((res) => {
          if (res.data.status === 200) {
            $('#giftModal').modal('hide');
            vm.search();
          } else {
            $('#giftModal').modal('hide');
            Swal.fire({
              icon: 'error',
              html: '獎品更新失敗<br>請重新新增或編輯獎品資訊',
              confirmButtonColor: '#dc3545',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          vm.pageSize = vm.tableData.length;
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    validate(value) {
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
    closeModal() {
      $('#giftModal').modal('hide');
    },
    delModal(details) {
      // show delete modal
      const vm = this;
      vm.giftDetails = details;
      $('#delGiftModal').modal('show');
    },
    delConfirm(details) {
      // 確認刪除 button
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/gift/delete`;
      vm.$http
        .post(api, { giftId: details.giftId })
        .then((res) => {
          if (res.data.status === 200) {
            $('#delGiftModal').modal('hide');
            vm.search();
          } else {
            $('#delGiftModal').modal('hide');
          }
          // eslint-disable-next-line no-unused-vars
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // console.log(error);
        });
    },
    cancelModal() {
      // 取消 button
      $('#delGiftModal').modal('hide');
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) {
      // 分頁
    },
    displayRecords() {
      // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      // console.log(vm.tableData);
      // console.log(vm.tableData.slice(startIndex, endIndex));
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) {
      // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() {
      // 排序Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        const A = a[type];
        const B = b[type];
        if (vm.isReverse) {
          if (typeof A === 'number') {
            if (B > A) {
              return -1;
            }
            if (A > B) {
              return 1;
            }
            return 0;
            // eslint-disable-next-line no-else-return
          } else {
            return A.localeCompare(B, 'zh-Hant');
          }
          // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (typeof A === 'number') {
            if (A > B) {
              return -1;
            }
            if (B > A) {
              return 1;
            }
            return 0;
            // eslint-disable-next-line no-else-return
          } else {
            return B.localeCompare(A, 'zh-Hant');
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
};
</script>
