<template>
  <div>
    <h1 class="title m-4">會員清單</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords mx-2">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input
              type="text"
              class="form-control"
              name="keyword"
              id="keyword"
              placeholder="輸入姓名或手機"
              v-model.trim="searchItem.keyword"
            />
          </div>
        </div>
        <div>
          <h5>註冊時間:</h5>
          <div class="calendar d-flex align-items-center">
            <div class="dateSelect d-flex">
              <datepicker
                v-model="searchItem.startDate"
                inputFormat="yyyy/MM/dd"
                :upper-limit="searchItem.endDate == '' ? false : searchItem.endDate"
                placeholder="起始日"
              />
              <span><i class="fas fa-calendar"></i></span>
            </div>
            <span class="mx-1">~</span>
            <div class="dateSelect d-flex">
              <datepicker
                v-model="searchItem.endDate"
                inputFormat="yyyy/MM/dd"
                :lower-limit="searchItem.startDate == '' ? false : searchItem.startDate"
                placeholder="結束日"
              />
              <span><i class="fas fa-calendar"></i></span>
            </div>
            <button class="btn-main btn searching" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢
            </button>
          </div>
        </div>
      </form>
      <div class="add text-right d-flex justify-content-">
        <button class="btn-main btn" @click="download">
          <i class="fas fa-download"></i>數據下載
        </button>
      </div>
    </div>
    <div class="container">
      <div class="page d-flex justify-content-between align-items-center">
        <div class="select-perpage d-flex align-items-center">
          <span>每頁顯示</span>
          <select class="form-select" v-model.number="perpage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <!-- <input type="number" v-model.number="perpage"/> -->
          <span>筆</span>
        </div>
      </div>
      <table class="table table-hover mt-4">
        <thead class="bg-thead">
          <tr>
            <th width="180" class="text-center" @click="changeType('userName')">
              姓名
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userName'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="150" class="text-center" @click="changeType('userPhone')">
              手機
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userPhone'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="150" class="text-center">
              出生年月日
            </th>
            <th width="120" class="text-center" @click="changeType('userAge')">
              年齡
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userAge'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="200" class="text-center" @click="changeType('userMail')">
              Email
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userMail'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="180" class="text-center" @click="changeType('userIdStatus')">
              身份認證
              <span class="icon" :class="{ inverse: isReverse }" v-if="sortType == 'userIdStatus'">
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
            <th width="150" class="text-center">
              常用地址 1
            </th>
            <th width="150" class="text-center">
              常用地址 2
            </th>
            <th width="150" class="text-center">
              常用地址 3
            </th>
            <th width="200" class="text-center" @click="changeType('registeredTime')">
              註冊時間
              <span
                class="icon"
                :class="{ inverse: isReverse }"
                v-if="sortType == 'registeredTime'"
              >
                <i class=" fas fa-angle-up text-success"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in displayRecords()" :key="index">
            <td class="text-center">{{ item.userName }}</td>
            <td class="text-center">{{ item.userPhone }}</td>
            <td class="text-center">{{ item.userBirth }}</td>
            <td class="text-center">{{ item.userAge }}</td>
            <td class="text-center">{{ item.userMail }}</td>
            <template v-if="item.userIdStatus != '認證成功'">
              <td class="text-center status_danger">
                <button
                  class="customCheck"
                  v-if="item.userIdStatus == '審核中'"
                  @click="toCheckUser(item.userPhone)"
                >
                  {{ item.userIdStatus }}
                </button>
                <button v-else>
                  {{ item.userIdStatus }}
                </button>
              </td>
            </template>
            <template v-else>
              <td class="text-center status_success">
                <button>認證成功</button>
              </td>
            </template>
            <td class="text-center">{{ item.commonAddress1 }}</td>
            <td class="text-center">{{ item.commonAddress2 }}</td>
            <td class="text-center">{{ item.commonAddress3 }}</td>
            <td class="text-center">{{ item.registeredTime }}</td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end">
        <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import Datepicker from 'vue3-datepicker';
import Pagination from '../components/pagination.vue';
// eslint-disable-next-line camelcase
import { export_json_to_excel } from '../Excel/Export2Excel';

export default {
  components: {
    Datepicker,
    Pagination,
  },
  data() {
    return {
      searchItem: {
        keyword: '',
        startDate: '',
        endDate: '',
      },
      tableData: '',
      sortType: 'userName',
      isReverse: true,
      pageSize: '',
      perpage: 30,
      page: 1,
      staffId: '',
    };
  },
  methods: {
    getFormData(object) {
      // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) {
      // 日期格式轉換
      const date = new Date(str);
      const mnth = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) return '';
      return [date.getFullYear(), mnth, day].join('/');
    },
    search() {
      // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/user/userInfo`;
      vm.$http
        .post(
          api,
          vm.getFormData({
            keyword: vm.searchItem.keyword,
            startDate: vm.convert(vm.searchItem.startDate),
            endDate: vm.convert(vm.searchItem.endDate),
          })
        )
        .then((res) => {
          if (res.data.status === 200) {
            vm.tableData = res.data.members;
          } else {
            vm.tableData = [];
            Swal.fire({
              icon: 'warning',
              text: '查無相關資訊',
              confirmButtonColor: '#ec7805',
              confirmButtonText: '關閉',
              allowOutsideClick: false,
            });
          }
          vm.pageSize = vm.tableData.length;
          // eslint-disable-next-line no-unused-vars
        })
        .catch(() => {
          // console.log(error);
          // Swal.fire({
          //   icon: 'error',
          //   html: '暫時無法取得資訊<br>請稍候再試',
          //   confirmButtonColor: '#dc3545',
          //   confirmButtonText: '關閉',
          //   allowOutsideClick: false,
          // });
        });
    },
    toCheckUser(userPhone) {
      // 身份認證為'審核中' -> 前往身份審核表單
      const vm = this;
      const userphone = btoa(userPhone);
      vm.$router.push(`/admin/identityCheck/${vm.staffId}/${userphone}`);
    },
    convertData(filter, ArrayData) {
      // 數據下載資料格式轉換
      return ArrayData.map((v) => filter.map((j) => String(v[j])));
    },
    download() {
      // 數據下載
      const vm = this;
      const data = vm.tableData;
      const date = vm
        .convert(new Date())
        .split('/')
        .join('');
      require.ensure([], () => {
        const tHeader = [
          '姓名',
          '手機',
          '出生年月日',
          '年齡',
          'Email',
          '身份認證狀態',
          '常用地址 1',
          '常用地址 2',
          '常用地址 3',
          '註冊時間',
        ];
        const filter = [
          'userName',
          'userPhone',
          'userBirth',
          'userAge',
          'userMail',
          'userIdStatus',
          'commonAddress1',
          'commonAddress2',
          'commonAddress3',
          'registeredTime',
        ];
        const convertedData = vm.convertData(filter, data);
        export_json_to_excel(tHeader, convertedData, `${date}_會員清單`);
      });
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) {
      // 分頁
    },
    displayRecords() {
      // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) {
      // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() {
      // 排序 Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        let A;
        let B;
        if (type === 'uploadNum' || type === 'matchNum') {
          A = a.memberDetails[type];
          B = b.memberDetails[type];
        } else {
          A = a[type];
          B = b[type];
        }
        if (vm.isReverse) {
          if (A === null || B === null) {
            if (A === null && B !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (A !== null && B === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (B > A) {
                return -1;
              }
              if (A > B) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return A.localeCompare(B, 'zh-Hant');
            }
          }
          // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (A === null || B === null) {
            if (B === null && A !== null) {
              return -1;
              // eslint-disable-next-line no-else-return
            } else if (B !== null && A === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof A === 'number') {
              if (A > B) {
                return -1;
              }
              if (B > A) {
                return 1;
              }
              return 0;
              // eslint-disable-next-line no-else-return
            } else {
              return B.localeCompare(A, 'zh-Hant');
            }
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
  activated() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
};
</script>
