import { createApp } from 'vue';
import 'bootstrap';
import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email, min, size } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import zhTW from '@vee-validate/i18n/dist/locale/zh_TW.json';
import Select2 from 'vue3-select2-component';
import VCalendar from 'v-calendar';
import Pagination from 'v-pagination-3';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('size', size);
configure({
  generateMessage: localize('zh_TW', {
    messages: {
      ...zhTW.messages,
      size: '檔案須小於 0:{size}KB',
    },
  }),
  validateOnInput: true,
});
setLocale('zh_TW');

const app = createApp(App).use(router);
app.component('Form', Form);
app.component('Field', Field);
app.component('ErrorMessage', ErrorMessage);
app.component('Select2', Select2);
app.component('pagination', Pagination);
app.use(VCalendar, {});
app.use(VueAxios, axios);
app.mount('#app');
